import {Component} from 'react'
import type {GetDerivedStateFromError, ErrorInfo, ReactNode} from 'react'

type ErrorBoundaryProps = {
  children?: ReactNode
  onError?: (error: unknown, errorInfo?: ErrorInfo) => void
}

type ErrorBoundaryState = {
  hasError: boolean
  error?: unknown
  eventId?: string
}

/** Нужен для того, чтобы ошибки внутри компонент никак не влияли на остальное приложение */
export class PassedErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {hasError: false, error: undefined}
  }

  static getDerivedStateFromError: GetDerivedStateFromError<{}, ErrorBoundaryState> = (error: unknown) => {
    return {hasError: true, error}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState((state) => {
      return {...state, hasError: true}
    })

    this.props.onError?.(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

import {useExp3} from '@eda-restapp/configs'
import type {FC} from 'react'

import {useSnowfallEffect} from './useSnowfallEffect'

type SnowfallProps = {
  anchorEl: HTMLElement | null
}

export const Snowfall: FC<SnowfallProps> = ({anchorEl}) => {
  const newYearConfig = useExp3('restapp_new_year')

  useSnowfallEffect(anchorEl, {
    enabled: !!newYearConfig.snowfall?.enabled,
    timeToLive: newYearConfig.snowfall?.timeToLive ?? 0,
    maxDayCount: newYearConfig.snowfall?.maxDayCount ?? 0,
    highpEnabled: newYearConfig.snowfall?.highpEnabled ?? false
  })

  return null
}

import {getServiceBrandLabels, useI18n} from '@eda-restapp/i18n'
import {Button, Typography, Box, Switch, RadioGroup, Radio, RestaurantSelectModalContainer} from '@eda-restapp/ui'
import React, {useEffect, useState, useMemo, useCallback} from 'react'

import type {PlacePlus, Service} from '@restapp/shared-api'
import {YaPlusStatusEnum} from '@restapp/shared-api'
import {DeliveryIcon} from '@restapp/shared-ui'

import ContractRestaurantHint from '../../ContractRestaurantHint'
import {PlacesListPlus} from '../PlacesListPlus'

import styles from './PlusServiceSelector.module.css'

export interface PlusServiceSelectorProps {
  places?: PlacePlus[]
  isSecondDrawer?: boolean
  selectedPlaces: number[]
  services: Service[]
  setServices: (values: Service[]) => void
  setSelectedPlaces: (values: number[]) => void
  isOneAvailableUnitedPlace?: boolean
  isSingleServicePlaces: boolean
  isExpandedPlacesList?: boolean
}

const PlusServiceSelector: React.FC<PlusServiceSelectorProps> = ({
  places,
  isSecondDrawer,
  selectedPlaces,
  setSelectedPlaces,
  isOneAvailableUnitedPlace,
  setServices,
  services,
  isSingleServicePlaces,
  isExpandedPlacesList = false
}) => {
  const {t} = useI18n()
  const {dcServiceBrandLabel, eatsServiceBrandLabel} = getServiceBrandLabels()

  const [isAutoChoice, setIsAutoChoice] = useState(true && !isOneAvailableUnitedPlace)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleRadioChange = (service: string) => {
    if (service === 'all') {
      setServices(['eda', 'dc'])
    } else {
      setServices([service as Service])
    }

    if (!isOneAvailableUnitedPlace) {
      setSelectedPlaces([])
    }
  }

  const notActivePlaces = useCallback(
    (service?: Service) =>
      places
        ?.filter((place) =>
          place.plus?.find((item) => {
            const notActiveItem =
              item.status_info.status === YaPlusStatusEnum.CAN_BE_ACTIVATED ||
              item.status_info.status === YaPlusStatusEnum.CANNOT_BE_ACTIVATED

            return service ? item.service === service && notActiveItem : notActiveItem
          })
        )
        .sort((a, b) => Number(b.isAvailable) - Number(a.isAvailable)),
    [places]
  )

  const availablePlacesByService = useCallback(
    (service?: Service) =>
      places?.filter(
        (place) =>
          place.isAvailable &&
          place.plus
            ?.filter((item) => (service ? item.service === service : true))
            .find((item) => item.status_info.status === YaPlusStatusEnum.CAN_BE_ACTIVATED)
      ),
    [places]
  )

  const filteredNonActivePlaces = notActivePlaces(
    services.length > 1 && !isSingleServicePlaces ? undefined : services[0]
  )

  const options = filteredNonActivePlaces?.map((place) => {
    let contractAnnotation

    const contractServices = place?.contractInfo
      ?.filter((serviceInfo) => serviceInfo.contract_type === 'contract')
      .map((serviceInfo) => serviceInfo.service)

    if (contractServices && contractServices.length > 0 && contractServices.some((item) => services.includes(item))) {
      contractAnnotation = (
        <Box mt='s' alignItems='center' className={styles.contractInfo}>
          <ContractRestaurantHint />
        </Box>
      )
    }

    return {
      id: place.id,
      title: place.name,
      address: place.address,
      icon: <DeliveryIcon place={place} fixZIndex />,
      disabled: Boolean(place.ratingError),
      annotation: place.ratingError || contractAnnotation
    }
  })

  const startValues = options?.reduce((acc: number[], place) => {
    if (!place.disabled) {
      acc.push(place.id)
    }

    return acc
  }, [])

  const isAvailableEda = useMemo(() => availablePlacesByService('eda')?.length !== 0, [availablePlacesByService])
  const isAvailableDc = useMemo(() => availablePlacesByService('dc')?.length !== 0, [availablePlacesByService])

  const isAllEdaPlacesActive = useMemo(
    () => !isAvailableEda && notActivePlaces('eda')?.length === 0,
    [isAvailableEda, notActivePlaces]
  )
  const isAllDcPlacesActive = useMemo(
    () => !isAvailableDc && notActivePlaces('dc')?.length === 0,
    [isAvailableDc, notActivePlaces]
  )

  const hasAvailablePlaces = availablePlacesByService()?.length !== 0
  const hasPlaces = places && places.length > 0

  useEffect(() => {
    if (!isSingleServicePlaces) {
      setServices(['eda', 'dc'])
    }

    if (isAutoChoice) {
      if (isAvailableEda && !isAvailableDc) {
        setServices(['eda'])
      }

      if (isAvailableDc && !isAvailableEda) {
        setServices(['dc'])
      }

      setSelectedPlaces(availablePlacesByService()?.map((item) => item.id) || [])
    } else {
      setSelectedPlaces([])
    }

    if (isAllEdaPlacesActive && !isAllDcPlacesActive) {
      setServices(['dc'])
    }

    if (isAllDcPlacesActive && !isAllEdaPlacesActive) {
      setServices(['eda'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoChoice, isAllEdaPlacesActive, isAllDcPlacesActive, isSingleServicePlaces, isAvailableEda, isAvailableDc])

  const isOnePlace = filteredNonActivePlaces?.length === 1

  return (
    <div>
      {(hasPlaces || isAutoChoice) &&
        !isOneAvailableUnitedPlace &&
        (!isSecondDrawer || !isOnePlace || !isSingleServicePlaces) && (
          <>
            <Box justifyContent='space-between' flexWrap='nowrap'>
              <Typography
                Component='label'
                variant='body2'
                lineHeight='normal'
                thickness='medium'
                htmlFor='both-service-switch'
                data-testid={'plus-service-selector-title' /* Плюс | Блок выбора ресторанов | Заголовок */}
              >
                {isSecondDrawer
                  ? t(
                      'shared-places.plus-service-selector.activate-plus-to-remaining-services',
                      'Подключить Плюс в оставшихся ресторанах'
                    )
                  : t(
                      'shared-places.plus-service-selector.activate-plus-to-all-services',
                      'Подключить Плюс во всех ресторанах'
                    )}
              </Typography>
              <Switch
                disabled={!hasAvailablePlaces}
                size='s'
                active={isAutoChoice}
                id='both-service-switch'
                onChange={() => setIsAutoChoice(!isAutoChoice)}
              />
            </Box>
            <Typography
              data-testid={
                'plus-service-selector-recommendation' /* Плюс | Блок выбора ресторанов | Строка рекомендации */
              }
              variant='caption1'
              lineHeight='normal'
              thickness='regular'
              color='minor'
              mt='s'
              mb='s'
            >
              {(isAvailableEda && !isAvailableDc) || (!isAvailableEda && isAvailableDc)
                ? t(
                    'shared-places.plus-service-selector.one-service-recommendation-subtitle',
                    'Советуем подключать Плюс во всех ресторанах, чтобы привлекать больше пользователей'
                  )
                : t(
                    'shared-places.plus-service-selector.recommendation-subtitle',
                    'Советуем подключать Плюс и на сервисе {eatsServiceBrandLabel}, и на сервисе {dcServiceBrandLabel} — чтобы привлекать ещё больше пользователей'
                  )}
            </Typography>
          </>
        )}

      {isAutoChoice && places && (
        <PlacesListPlus
          initialExpanded={(isOnePlace && isSecondDrawer) || isExpandedPlacesList}
          isSelect
          places={notActivePlaces()}
        />
      )}

      {!isAutoChoice && (
        <Box flexDirection='column' data-testid={'plus-service-selector-block' /* Плюс | Блок выбора сервиса */}>
          {hasPlaces && (
            <>
              {!isSingleServicePlaces && (
                <>
                  <Typography
                    variant='body2'
                    lineHeight='normal'
                    thickness='medium'
                    mt={isOneAvailableUnitedPlace ? undefined : 'm'}
                    mb='m'
                  >
                    {t('shared-places.plus-service-selector.choose-service-title', 'Выберите сервис:')}
                  </Typography>

                  <RadioGroup.List name='service' value=''>
                    <div>
                      <Box alignItems='center' flexWrap='nowrap'>
                        <Radio
                          value='all'
                          name='all'
                          id='all'
                          disabled={isAllEdaPlacesActive || isAllDcPlacesActive}
                          checked={services.includes('dc') && services.includes('eda')}
                          data-testid={
                            'plus-service-selector-all-option' /* Плюс| Блок выбора сервиса | Радиобаттон Все сервисы */
                          }
                          onChange={(e) => handleRadioChange(e.target.value)}
                        />
                        <Typography
                          Component='label'
                          variant='body2'
                          lineHeight='normal'
                          thickness='regular'
                          htmlFor='all'
                          ml='m'
                          data-testid={
                            'plus-service-selector-all-text' /* Плюс| Блок выбора сервиса | Текст Все сервисы */
                          }
                        >
                          {t('shared-places.plus-service-selector.all-services', 'Все сервисы')}
                        </Typography>
                      </Box>
                    </div>
                    <div>
                      <Box alignItems='center' flexWrap='nowrap' mt='m'>
                        <Radio
                          value='eda'
                          name='eda'
                          id='eda'
                          checked={services.includes('eda') && services.length < 2}
                          disabled={isAllEdaPlacesActive}
                          data-testid={
                            'plus-service-selector-eda-option' /* Плюс| Блок выбора сервиса | Радиобаттон ЯЕ */
                          }
                          onChange={(e) => handleRadioChange(e.target.value)}
                        />
                        <Box flexDirection='column'>
                          <Typography
                            Component='label'
                            variant='body2'
                            lineHeight='normal'
                            thickness='regular'
                            htmlFor='eda'
                            ml='m'
                            data-testid={'plus-service-selector-eda-text' /* Плюс| Блок выбора сервиса | Текст ЯЕ */}
                          >
                            {eatsServiceBrandLabel}
                          </Typography>
                          {isAllEdaPlacesActive && (
                            <Typography
                              Component='label'
                              variant='caption1'
                              lineHeight='normal'
                              thickness='regular'
                              htmlFor='eda'
                              color='minor'
                              ml='m'
                              data-testid={
                                'plus-service-selector-eda-hint' /* Плюс| Блок выбора сервиса | Подсказка сервиса ЯЕ */
                              }
                            >
                              {t('shared-places.plus-service-selector.all-places-active', 'Все рестораны подключены')}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </div>
                    <div>
                      <Box
                        alignItems='center'
                        flexWrap='nowrap'
                        mt='m'
                        mb={isOneAvailableUnitedPlace ? undefined : 'm'}
                      >
                        <Radio
                          value='dc'
                          name='dc'
                          id='dc'
                          disabled={isAllDcPlacesActive}
                          checked={services.includes('dc') && services.length < 2}
                          data-testid={
                            'plus-service-selector-dc-option' /* Плюс| Блок выбора сервиса | Радиобаттон МД */
                          }
                          onChange={(e) => handleRadioChange(e.target.value)}
                        />
                        <Box flexDirection='column'>
                          <Typography
                            Component='label'
                            variant='body2'
                            lineHeight='normal'
                            thickness='regular'
                            htmlFor='dc'
                            ml='m'
                            data-testid={'plus-service-selector-dc-text' /* Плюс| Блок выбора сервиса | Текст МД */}
                          >
                            {dcServiceBrandLabel}
                          </Typography>
                          {isAllDcPlacesActive && (
                            <Typography
                              Component='label'
                              variant='caption1'
                              lineHeight='normal'
                              thickness='regular'
                              htmlFor='eda'
                              color='minor'
                              ml='m'
                              data-testid={
                                'plus-service-selector-dc-hint' /* Плюс| Блок выбора сервиса | Подсказка сервиса МД */
                              }
                            >
                              {t('shared-places.plus-service-selector.all-places-active', 'Все рестораны подключены')}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </div>
                  </RadioGroup.List>
                </>
              )}

              {!isOneAvailableUnitedPlace && (
                <>
                  <Typography
                    variant='body2'
                    lineHeight='normal'
                    thickness='medium'
                    mb='m'
                    data-testid={
                      'plus-service-selector-restaurants-to-connect-text' /* Плюс| Рестораны для подключения */
                    }
                  >
                    {t('shared-places.plus-service-selector.restaurants-to-connect', 'Рестораны для подключения:')}
                  </Typography>

                  {selectedPlaces.length > 0 ? (
                    <>
                      <Box mb='m' flexDirection='column'>
                        <PlacesListPlus
                          initialExpanded={isExpandedPlacesList}
                          places={filteredNonActivePlaces}
                          isSelect
                          selectedPlaces={selectedPlaces}
                        />
                      </Box>
                      <Button.Text
                        data-testid='plus-service-selector-edit-button'
                        variant='secondary'
                        size='m'
                        fullWidth
                        onClick={() => setIsOpenModal(true)}
                      >
                        {t('shared-places.plus-service-selector.edit-button-text', 'Изменить')}
                      </Button.Text>
                    </>
                  ) : (
                    <Button.Text
                      data-testid='plus-service-selector-choose-restaurants-button'
                      fullWidth
                      onClick={() => setIsOpenModal(true)}
                    >
                      {t('shared-places.plus-service-selector.choose-restaurants', 'Выбрать рестораны')}
                    </Button.Text>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      )}

      <RestaurantSelectModalContainer
        isOpen={isOpenModal}
        options={options || []}
        values={(selectedPlaces.length > 0 ? selectedPlaces : undefined) || startValues || []}
        onChange={setSelectedPlaces}
        onClose={() => setIsOpenModal(false)}
      />
    </div>
  )
}

export default PlusServiceSelector

import {useExp3} from '@eda-restapp/configs'
import {usePermission} from '@eda-restapp/permissions'

import {usePlaces} from '@restapp/core-places'

import {useSubscriptions} from './useSubscriptions'

export function useSubscriptionButton() {
  const hasAccess = usePermission(['permission.tools.common', 'permission.tools.subscription.common'])
  const toolsConfig = useExp3('restapp_tools')

  const placesQuery = usePlaces()
  const {data} = useSubscriptions(
    {placesIds: placesQuery.placesIds},
    {enabled: !!placesQuery.placesIds.length && toolsConfig.enabledSubscription}
  )

  return {
    isShow: hasAccess && !!data?.subscriptions.length
  }
}

import {useExp3} from '@eda-restapp/configs'
import {usePermission} from '@eda-restapp/permissions'
import {compact} from 'lodash-es'

import {useHeavyPlaces} from '@restapp/core-places'

import {useSmartPrices} from './useSmartPrices'
import {useSubscriptions} from './useSubscriptions'

export const useCanBeShownPlacesAlerts = (placeIds: number[]) => {
  const {getPlaceById, getPlacesByBusinessType} = useHeavyPlaces({enabled: !!placeIds.length, limit: 999})
  const places = compact(placeIds.map((placeId) => getPlaceById(placeId)))
  const restaurants = getPlacesByBusinessType('restaurant')
  const restaurantsIds = restaurants.map((place) => place.id) || []
  const placesIds = places.map((place) => place.id) || []

  const restappTools = useExp3('restapp_tools')
  const canShowAlerts = usePermission('permission.places.alerts')

  const isSubscriptionEnabled = restappTools.enabled && restappTools.subscriptionPage?.enabled

  const subscriptionsQuery = useSubscriptions(
    {placesIds},
    {
      enabled: isSubscriptionEnabled && !!placesIds.length,
      select: (data) => data?.subscriptions
    }
  )
  const hasNotUpdatedPartner = Boolean(
    subscriptionsQuery.data?.some((subscription) => !subscription.is_partner_updated)
  )

  /**
   * Баннеры подписки
   */
  const isCanBeShownSubscriptionAnyBanner = canShowAlerts && isSubscriptionEnabled && subscriptionsQuery.isSuccess
  const isCanBeShownSubscriptionAlert = isCanBeShownSubscriptionAnyBanner && hasNotUpdatedPartner
  const isCanBeShownSubscriptionDiscountAlert =
    isCanBeShownSubscriptionAnyBanner && restappTools.subscriptionPage.discountAlertEnabled

  /**
   * Баннеры умной цены
   */
  const SmartPricesQuery = useSmartPrices(restaurantsIds, {
    enabled: restappTools.enabledSmartPrice && !!restaurantsIds.length
  })
  const isEveryActive = SmartPricesQuery.data?.every((smartPrice) => smartPrice.status === 'active')
  const isCanBeShownSmartPricesAlert = canShowAlerts && SmartPricesQuery.isSuccess && !isEveryActive

  return {
    hasPlaces: Boolean(placesIds.length),
    isCanBeShownSubscriptionAlert,
    isCanBeShownSubscriptionDiscountAlert,
    isCanBeShownSmartPricesAlert
  }
}

/**
 * На десктопе сайдбар может разъезжаться.
 * Если пересчитывать ширину, снег будет пропадать, поэтому он указан статичным
 * Для остальных разрешений ширина соответствует размеру Paper, так как нет анимаций
 * */
export const DESKTOP_SNOWFALL_CANVAS_WIDTH = 400

/** Позволяет удалять снег с изменением opacity, когда TTL истек */
export const SOFT_DESTRUCT_TIMEOUT = 500

/** Время, через которое канвас будет полностью удален, а компонент размонтирован, когда TTL истек */
export const HARD_DESTRUCT_TIMEOUT = 700

export const WAS_SHOWN_LOCAL_STORAGE_KEY = 'restapp:snowfall:was-shown'

export const DAY_SHOWN_COUNT_LOCAL_STORAGE_KEY = 'restapp:snowfall:interval-shown-count'

export const LAST_SHOW_STARTED_LOCAL_STORAGE_KEY = 'restapp:snowfall:last-show-started'

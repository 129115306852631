import cn from 'classnames'
import React, {type FC} from 'react'

import TariffBadge from '@restapp/subscription/components/TariffBadge'

import {useTariffBadge} from '../../hooks/useTariffBadge'

import {useStyles} from './TariffBadgeContainer.styles'

interface TTariffBadgeContainerProps {
  placeId: number
  className?: string
  isShowMobile?: boolean
}

export const TariffBadgeContainer: FC<TTariffBadgeContainerProps> = ({placeId, className, isShowMobile}) => {
  const {classes: c} = useStyles()

  const tariffBadgeInfo = useTariffBadge(placeId)

  if (
    !tariffBadgeInfo ||
    !tariffBadgeInfo.isSubscriptionEnabled ||
    !tariffBadgeInfo.tariffType ||
    tariffBadgeInfo.tariffType === 'free'
  ) {
    return null
  }

  return (
    <TariffBadge
      className={cn(className, !tariffBadgeInfo.isNeedAlerting && !isShowMobile && c.hideTariffBadgeOnMobile)}
      untilDate={tariffBadgeInfo.validUntilIso}
      tariffType={tariffBadgeInfo.tariffType}
      showTooltip={tariffBadgeInfo.isNeedAlerting}
      isShowMobile={isShowMobile}
      isTrial={tariffBadgeInfo.isTrial}
      isPaidNextTariff={tariffBadgeInfo.nextTariff !== 'free'}
    />
  )
}

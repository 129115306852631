import {errorLogger, eventLogger, metrika, RUM} from '@eda-restapp/logger'
import {notificationsChannel} from '@eda-restapp/microfrontend'
import {useSupportChat} from '@restapp/main-chats/hooks/useSupportChat'
import {chatsSlice} from '@restapp/main-chats/slice/chatsSlice'
import {memo, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {nativeSlice} from '../native/slice/native.slice'
import {broadcastXivaNotification} from './broadcastXivaNotification'

const eventLog = eventLogger.namespace('ProcessClickedNotifications')
const errorLog = errorLogger.namespace('ProcessClickedNotifications')

export const ProcessClickedNotifications = memo(() => {
  const navigate = useNavigate()
  const {openChat} = useSupportChat()
  const dispatch = useDispatch()

  useProcessClickedPushEvent()

  useEffect(
    () =>
      notificationsChannel.subscribe((notification) => {
        if (!notification.meta.clicked) {
          return
        }

        eventLog({
          name: 'notification',
          value: `${notification.event}:${notification.meta.source}`,
          additional: notification
        })

        metrika({
          target: 'notification_clicked',
          params: {
            event: notification.event,
            source: notification.meta.source
          }
        })

        switch (notification.event) {
          case 'order_changed_courier':
          case 'order_changed_items':
          case 'order_changed_status':
          case 'order_updated_courier':
          case 'order_new': {
            if (notification.event === 'order_new') {
              RUM.setScenariosTimeStamp('new_order_push_click')
            }
            navigate(`/active/${notification.data.orderNr}`)
            break
          }

          case 'direct_low_balance': {
            navigate('/promotion/places/?modal=payment')
            break
          }

          case 'place_leader_changed': {
            navigate(`/place-leaders/${notification.data.place_id}`)
            break
          }

          case 'place_blocked': {
            if (notification.data.type === 'single' && notification.data.place_id) {
              navigate(`/places/${notification.data.place_id}/info?blockInfoPlaceId=${notification.data.place_id}`)
            } else {
              navigate('/places')
            }
            break
          }

          case 'new_yamb_csat_message':
          case 'new_yamb_message': {
            if (notification.data.chat_id) {
              dispatch(
                chatsSlice.actions.openMultiChatDrawer({
                  chatId: notification.data.chat_id,
                  ticketId: notification.data.ticket_id
                })
              )
            }
            break
          }

          case 'new_message': {
            openChat(notification.data.chat_id)
            break
          }

          default:
            break
        }
      }),
    [dispatch, navigate, openChat]
  )

  return null
})

function useProcessClickedPushEvent() {
  const clickedEvent = useSelector(nativeSlice.selectors.selectClickedEvent)

  useEffect(() => {
    if (!clickedEvent) {
      return
    }

    broadcastXivaNotification(notificationsChannel, clickedEvent.payload, {clicked: true, source: 'push'})
      .then(() => {
        eventLog({
          name: 'useProcessClickedPushEvent',
          value: clickedEvent.payload.eventData?.event || 'unknown',
          additional: {clickedEvent}
        })
      })
      .catch((error) =>
        errorLog({
          level: 'error',
          sourceType: 'useProcessClickedPushEvent',
          additional: {clickedEvent},
          error: error
        })
      )
  }, [clickedEvent])
}

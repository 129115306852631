import React, {type MouseEvent, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {AccountPopoverContainer} from '@restapp/core-auth'
import {useUserInfo} from '@restapp/core-auth/hooks'
import {useGetAvatarUrl} from '@restapp/core-auth/passport/hooks/useGetAvatarURL'

import {LegacySidebarLogout} from './LegacySidebarLogout'
import {SidebarAccountElement} from './SidebarAccountElement'

type SidebarAccountElementContainerProps = {
  mini: boolean
  isOnboardingOpen: boolean
  closeOnboarding: () => void
  onNavigationPerformed: () => void
}

export const SidebarAccountElementContainer: React.FC<SidebarAccountElementContainerProps> = ({
  mini,
  isOnboardingOpen,
  closeOnboarding,
  onNavigationPerformed
}) => {
  const userInfo = useUserInfo()

  const [isAccountPopoverOpen, setIsAccountPopoverOpen] = useState(false)
  const [accountElementRef, setAccountRef] = useState<HTMLDivElement | null>(null)

  const navigate = useNavigate()
  const getAvatarUrl = useGetAvatarUrl()

  useEffect(() => {
    if (!accountElementRef) {
      return
    }

    if (isOnboardingOpen) {
      accountElementRef.scrollIntoView()
      setIsAccountPopoverOpen(true)
    }
  }, [accountElementRef, isAccountPopoverOpen, isOnboardingOpen])

  const isOldAuth = userInfo?.type === 'eats'

  if (!userInfo) {
    return null
  }

  if (isOldAuth) {
    return <LegacySidebarLogout email={userInfo.email} mini={mini} />
  }

  const openAccountPopover = (event: MouseEvent) => {
    event.stopPropagation() // FastFix. Without this useOnClickOutside in AccountPopoverContainer triggers immediately after open
    setIsAccountPopoverOpen(true)
  }

  const closeAccountPopover = () => {
    setIsAccountPopoverOpen(false)

    if (isOnboardingOpen) {
      closeOnboarding()
    }
  }

  const onCloseTooltip: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    if (isOnboardingOpen) {
      closeOnboarding()
    }
  }

  const avatarSrc = getAvatarUrl(userInfo.avatar)
  const currentCabinetName = userInfo.name || userInfo.email

  const handleNavigation = (path: string) => {
    navigate(path)
    closeAccountPopover()
    onNavigationPerformed()
  }

  const isSelectedPage = window.location.pathname.includes('/account/')

  return (
    <>
      <SidebarAccountElement
        ref={setAccountRef}
        avatarSrc={avatarSrc}
        login={userInfo.login}
        mini={mini}
        selected={isSelectedPage || isAccountPopoverOpen}
        cabinet={currentCabinetName}
        onClick={openAccountPopover}
      />

      <AccountPopoverContainer
        open={isAccountPopoverOpen}
        triggerElement={accountElementRef}
        onClose={closeAccountPopover}
        onCloseTooltip={onCloseTooltip}
        onNavigate={handleNavigation}
        showTooltip={isOnboardingOpen}
      />
    </>
  )
}

import {useI18n} from '@eda-restapp/i18n'
import {Box, ShoppingBagFillIcon, Typography, CoinsFillReverted, BonusIcon} from '@eda-restapp/ui'
import type {FC} from 'react'

import type {Currency} from '@restapp/promotion/types/common'

import styles from './PromoCampaignCard.module.css'

type PromoCampaignCardProps = {
  currency?: Currency
}

export const PromoCampaignCard: FC<PromoCampaignCardProps> = ({currency}) => {
  const {t, tRaw} = useI18n()

  return (
    <Box className={styles.root} flexDirection='column'>
      <Box>
        <Box className={styles.shoppingIconWrapper}>
          <ShoppingBagFillIcon className={styles.shoppingIcon} />
        </Box>
        <Typography variant='body2' thickness='medium' lineHeight='normal' className={styles.title}>
          {t('promotion.promo-campaign-card.title', 'Рекламная кампания')}
        </Typography>
      </Box>
      <Box className={styles.line} />
      <Box flexDirection='column'>
        <Box className={styles.item}>
          <CoinsFillReverted className={styles.itemIcon} />
          <Box flexDirection='column' className={styles.itemTexts}>
            <Typography variant='body2' lineHeight='normal' className={styles.itemTitle}>
              {t('promotion.promo-campaign-card.item-title-first', 'Оплата за заказы')}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.item}>
          <BonusIcon className={styles.itemIcon} />

          <Box flexDirection='column' className={styles.itemTexts}>
            <Typography variant='body2' lineHeight='normal' className={styles.itemTitle}>
              {t('promotion.promo-campaign-card.item-title-second', 'Применим бонусы для оплаты')}
            </Typography>
            <Typography variant='body2' className={styles.itemSubtitle}>
              {currency
                ? tRaw('promotion.promo-campaign-card.item-subtitle-with-sign', '1 {sign} {crossPrice}', {
                    sign: currency.sign,
                    crossPrice: (
                      <span className={styles.crossPrice}>
                        {t('promotion.promo-capmaign-card.cross-price-witn-sign', '100 {sign}', {
                          sign: currency.sign
                        })}
                      </span>
                    )
                  })
                : tRaw('promotion.promo-campaign-card.item-subtitle', '1 ₽ {crossPrice}', {
                    crossPrice: (
                      <span className={styles.crossPrice}>
                        {t('promotion.promo-capmaign-card.cross-price', '100 ₽')}
                      </span>
                    )
                  })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import {useExp3} from '@eda-restapp/configs'

import type {ShortRestaurant} from '@restapp/shared-api'

/** Хук только для интеграции с DC */
export function useDisabledPlusForDCPlace(place?: ShortRestaurant) {
  const plusConfig = useExp3('restapp_plus')

  if (!place || plusConfig.enabledForDC) {
    return false
  }

  return place.services?.enabled.length === 1 && place.services?.enabled.includes('dc')
}

import {useI18n} from '@eda-restapp/i18n'
import {usePermission} from '@eda-restapp/permissions'
import {Box, ChevronDownMIcon, Typography, Statistics} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import React, {useState} from 'react'

import type {PlusStatusByService, Service} from '@restapp/shared-api'
import {YaPlusStatusEnum} from '@restapp/shared-api'
import type {ShortRestaurant} from '@restapp/shared-api/types/places'
import PlusConditions from '@restapp/shared-places/components/PlusDrawer/PlusConditions'
import {RestaurantListItem} from '@restapp/shared-ui'
import {Button} from '@restapp/shared-ui/Button/Button'

import ContractRestaurantHint from '../../../ContractRestaurantHint'

import useStyles from './RestaurantListItemPlus.style'

interface Props {
  status?: YaPlusStatusEnum
  ratingError?: string
  place: ShortRestaurant
  showStats?: boolean
  onClick: () => void
  plus?: PlusStatusByService[]
  showInfo?: boolean
  hasContract?: boolean
  onDisable?: (service: Service[]) => void
}

const RestaurantListItemPlus: React.FC<Props> = ({
  ratingError,
  hasContract,
  status,
  showStats,
  onClick,
  onDisable,
  place,
  plus,
  showInfo
}) => {
  const {t} = useI18n()
  const {classes: c} = useStyles({status})
  const [isExpanded, setIsExpanded] = useState(false)

  const canSeePlusStats = usePermission('permission.stats.plus')
  const canEditPlus = usePermission('permission.plus.edit')

  const getStatusText = (status: YaPlusStatusEnum | undefined) => {
    switch (status) {
      case 'can_not_be_activated':
        return ratingError ? ratingError : 'Неизвестная ошибка'
      default:
        return
    }
  }

  const statusErrorText = getStatusText(status)

  const getSubTitle = () => {
    if (statusErrorText) {
      return (
        <div
          data-testid={`restaurant-item-status-${place.id}` /* Элемент списка | Статус ресторана */}
          className={c.status}
        >
          {statusErrorText}
        </div>
      )
    }
  }

  const isEveryServiceActive = plus?.every((serviceInfo) => {
    const status = serviceInfo.status_info.status

    return (
      status === YaPlusStatusEnum.ACTIVE ||
      status === YaPlusStatusEnum.ACTIVATING ||
      status === YaPlusStatusEnum.WAIT_TO_START ||
      status === YaPlusStatusEnum.DEACTIVATING
    )
  })

  return (
    <div className={c.root}>
      <div className={c.mainWrapper}>
        <RestaurantListItem key={place.id} place={place} />
        <Box flexDirection='row'>
          {showStats && canSeePlusStats && (
            <Button
              data-testid='statistic-promotion-button-on-drover'
              className={c.controlsButton}
              tooltipText={t('promotion.restaurant-list-item-plus.statistika-prodvizheniya', 'Статистика продвижения')}
              onClick={onClick}
            >
              <Statistics />
            </Button>
          )}
          {showInfo && (
            <Button className={c.controlsButton} onClick={() => setIsExpanded((prev) => !prev)}>
              <ChevronDownMIcon className={cn(c.arrow, isExpanded && c.arrowUp)} />
            </Button>
          )}
        </Box>
      </div>
      <>
        {statusErrorText ? (
          <Typography variant='caption1' mt='s'>
            {getSubTitle()}
          </Typography>
        ) : (
          hasContract && <ContractRestaurantHint />
        )}
      </>
      {isExpanded && (
        <Box flexDirection='column' className={c.condition}>
          {plus?.map((serviceWithPlus) => {
            const status = serviceWithPlus.status_info

            if (!status || status.value === undefined || status.starts === undefined) {
              return null
            }

            const isMultiServicePlace = plus.length > 1
            const isDeactivating = status.status === YaPlusStatusEnum.DEACTIVATING
            const showDisableAllServices = isMultiServicePlace && isEveryServiceActive

            return (
              <PlusConditions
                key={serviceWithPlus.service}
                mb='s'
                isShowInSelect
                onDisable={onDisable}
                showDisableAllServices={showDisableAllServices}
                service={serviceWithPlus.service}
                commission={status.value}
                startDate={moment(status.starts)}
                isDeactivating={isDeactivating}
                hasAccessToEditPlus={canEditPlus}
              />
            )
          })}
        </Box>
      )}
    </div>
  )
}

export default RestaurantListItemPlus

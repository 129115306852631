import {RUM} from '@eda-restapp/logger'
import cn from 'classnames'
import React, {type CSSProperties} from 'react'

import {useServiceBrand} from '@restapp/shared'

import useAppLogoStyles from './AppLogo.styles'
import logoYandexEats from './assets/yandex-eats-logo.svg'
import logoYandexEdaVendor from './assets/yandex-eda-vendor-logo.svg'
import logoYangoEats from './assets/yango-eats-logo.svg'

export type AppLogoProps = {
  style?: CSSProperties
  className?: string
}

const AppLogo: React.FC<AppLogoProps> = ({style, className}) => {
  const serviceBrand = useServiceBrand()

  const getEatsLogoURL = (brand: string) => {
    switch (brand) {
      case 'YandexEats':
        return {
          logoUrl: logoYandexEats,
          width: 296,
          height: 27
        }
      case 'YangoEats':
        return {
          logoUrl: logoYangoEats,
          width: 239,
          height: 27
        }
      default:
        // По умолчанию для localhost'а отображаем логотип "Яндекс Еда"
        return {
          logoUrl: logoYandexEdaVendor,
          width: 281,
          height: 27
        }
    }
  }

  const logo = getEatsLogoURL(serviceBrand)

  const {classes: c} = useAppLogoStyles()

  return (
    <img
      src={logo.logoUrl}
      style={{...style, width: logo.width, height: logo.height}}
      className={cn(c.logo, className)}
      onLoad={() => {
        RUM.sendScenariosDelta('core_init_auth_page', 'auth_page_full_load')
      }}
    />
  )
}

export default AppLogo

type ImagePromisePaths = {
  total: () => Promise<{default: string}>
  black: () => Promise<{default: string}>
  green: () => Promise<{default: string}>
  red: () => Promise<{default: string}>
  yellow: () => Promise<{default: string}>
}

export type ImagePaths = {
  total: string
  black: string
  green: string
  red: string
  yellow: string
}

const vendorImages: ImagePromisePaths = {
  total: () => import(/* webpackChunkName: "@restapp/new-year/vendorGarland" */ './assets/eats/Total_Vendor.webp'),
  black: () => import(/* webpackChunkName: "@restapp/new-year/vendorGarland" */ './assets/eats/Black_Vendor.webp'),
  green: () => import(/* webpackChunkName: "@restapp/new-year/vendorGarland" */ './assets/eats/Green_Vendor.webp'),
  red: () => import(/* webpackChunkName: "@restapp/new-year/vendorGarland" */ './assets/eats/Red_Vendor.webp'),
  yellow: () => import(/* webpackChunkName: "@restapp/new-year/vendorGarland" */ './assets/eats/Yellow_Vendor.webp')
}

const edaImages: ImagePromisePaths = {
  total: () => import(/* webpackChunkName: "@restapp/new-year/edaGarland" */ './assets/eda/Total_Eda.webp'),
  black: () => import(/* webpackChunkName: "@restapp/new-year/edaGarland" */ './assets/eda/Black_Eda.webp'),
  green: () => import(/* webpackChunkName: "@restapp/new-year/edaGarland" */ './assets/eda/Green_Eda.webp'),
  red: () => import(/* webpackChunkName: "@restapp/new-year/edaGarland" */ './assets/eda/Red_Eda.webp'),
  yellow: () => import(/* webpackChunkName: "@restapp/new-year/edaGarland" */ './assets/eda/Yellow_Eda.webp')
}

const yangoImages: ImagePromisePaths = {
  total: () => import(/* webpackChunkName: "@restapp/new-year/yangoGarland" */ './assets/yango/Total_Yango.webp'),
  black: () => import(/* webpackChunkName: "@restapp/new-year/yangoGarland" */ './assets/yango/Black_Yango.webp'),
  green: () => import(/* webpackChunkName: "@restapp/new-year/yangoGarland" */ './assets/yango/Green_Yango.webp'),
  red: () => import(/* webpackChunkName: "@restapp/new-year/yangoGarland" */ './assets/yango/Red_Yango.webp'),
  yellow: () => import(/* webpackChunkName: "@restapp/new-year/yangoGarland" */ './assets/yango/Yellow_Yango.webp')
}

export const imageMap: Record<string, ImagePromisePaths> = {
  YangoEats: yangoImages,
  YandexEda: edaImages,
  YandexEats: vendorImages
}

import {useExp3} from '@eda-restapp/configs'
import {promotionChannel, useChannelSubscription} from '@eda-restapp/microfrontend'
import {useState} from 'react'

import {useModal} from '@restapp/shared-modals'

import useCPACampaignsApi from '../../../../hooks/useCPACampaignsApi'
import {PROMOTION_BONUSES_DRAWER_NAME} from '../../constants'
import {useBonusesBalance} from '../../hooks/useBonusesBalance'

import {CpaCurrencyContext} from './hooks/useCpaCurrencyContext'
import {PromoDrawerContext} from './hooks/usePromoDrawerContext'
import {PromoDrawer} from './PromoDrawer'

export type Tab = 'availableBonuses' | 'allBonuses'

export const PromoDrawerContainer = () => {
  const promotionConfig = useExp3('restapp_promotion')
  const {isOpen, close, open} = useModal({modalName: PROMOTION_BONUSES_DRAWER_NAME})

  const bonusesBalance = useBonusesBalance({enabled: isOpen})
  const cpaCampaignsQuery = useCPACampaignsApi({
    enabled: isOpen && promotionConfig.cpaBonuses.isStartByBonusesAvailable
  })

  const campaigns = cpaCampaignsQuery.campaigns
  const currency = cpaCampaignsQuery.currency

  const [tab, setTab] = useState<Tab>('availableBonuses')

  useChannelSubscription(promotionChannel, (msg) => {
    if (msg.type === 'show-promo-bonuses-drawer') {
      open()
    }
  })

  if (!bonusesBalance.total || !promotionConfig.cpaBonuses.isStartByBonusesAvailable || !cpaCampaignsQuery.isFetched) {
    return null
  }

  const handleToggleTab = () => {
    setTab(tab === 'availableBonuses' ? 'allBonuses' : 'availableBonuses')
  }

  const handleCloseDrawer = () => {
    close()
    setTab('availableBonuses')
  }

  return (
    <PromoDrawerContext.Provider value={{campaigns}}>
      <CpaCurrencyContext.Provider value={{currency}}>
        <PromoDrawer isOpen={isOpen} onClose={handleCloseDrawer} tab={tab} onToggleDrawerTab={handleToggleTab} />
      </CpaCurrencyContext.Provider>
    </PromoDrawerContext.Provider>
  )
}

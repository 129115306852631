import {useI18n} from '@eda-restapp/i18n'

import type {PlaceSubscription} from '@restapp/shared-api'
import {getTariffCost} from '@restapp/subscription/utils/getTariffCost'

export const useSubscriptionCost = (subscription?: PlaceSubscription) => {
  const {t, lang} = useI18n()

  const getCommission = (additionalCommission?: string, fixCost?: string) => {
    const commission: string[] = []
    if (additionalCommission) {
      commission.push(additionalCommission)
    }
    if (fixCost) {
      commission.push(t('shared-places.use-subscription-cost.fix-cost', 'от {fixCost}', {fixCost}))
    }

    return commission.join(' + ')
  }

  if (!subscription) {
    return {}
  }

  const currentTariff = subscription.tariff_info
  const nextTariff = subscription.next_tariff_info
  const isTrial = subscription.is_trial

  const currentTariffCost = getTariffCost(currentTariff, lang, !!currentTariff.discount, isTrial)
  const currentOldTariffCost = getTariffCost(currentTariff, lang, undefined, isTrial)
  const nextTarrifCost = nextTariff ? getTariffCost(nextTariff, lang, !!nextTariff.discount) : undefined
  const nextOldTarrifCost = nextTariff ? getTariffCost(nextTariff, lang) : undefined

  const currentCommission = getCommission(currentTariffCost.additionalCommission, currentTariffCost.fixCost)
  const currentOldCommission = getCommission(currentOldTariffCost.additionalCommission, currentOldTariffCost.fixCost)
  const nextCommission = nextTarrifCost
    ? getCommission(nextTarrifCost.additionalCommission, nextTarrifCost.fixCost)
    : undefined
  const nextOldCommission = nextOldTarrifCost
    ? getCommission(nextOldTarrifCost.additionalCommission, nextOldTarrifCost.fixCost)
    : undefined

  return {
    currentTariffCost,
    nextTarrifCost,
    currentCommission:
      isTrial && !currentCommission
        ? t('shared-places.use-subscription-cost.trial-free-cost', 'Бесплатно')
        : currentCommission,
    currentOldCommission,
    nextCommission,
    nextOldCommission
  }
}

import type {Selector} from 'react-redux'

import {nativeSlice} from '@restapp/bootstrap/native/slice/native.slice'
import type {NativeStateSlice} from '@restapp/bootstrap/native/types'

import {getApplication, isNativeVendor} from '../utils'

export const selectDeviceHeaders: Selector<NativeStateSlice, Record<string, string>> = (state) => {
  const deviceHeaders: Record<string, string> = {}
  const deviceInfo = nativeSlice.selectors.selectDeviceInfo(state)
  const deviceId = isNativeVendor ? deviceInfo?.deviceId : 'web_device_id'

  if (deviceId) {
    deviceHeaders['X-Device-Id'] = deviceId
  }

  deviceHeaders['X-Platform'] = getApplication()

  return deviceHeaders
}

import type {Service, ShortRestaurant, IPlaceContractInfo} from './places'

export enum YaPlusUnavailableCode {
  LOW_RATING = 'low_rating',
  NO_RATING = 'no_rating',
  FEW_RATINGS = 'few_ratings',
  REGION_WITHOUT_PLUS = 'region_without_plus'
}

export const RATING_REASONS = [
  YaPlusUnavailableCode.FEW_RATINGS,
  YaPlusUnavailableCode.LOW_RATING,
  YaPlusUnavailableCode.NO_RATING
]

export interface YaPlusUnavailableReason {
  code: YaPlusUnavailableCode
  message: string
}

export type PlacePlus = ShortRestaurant & {
  hasLowRatingError?: boolean
  showStats?: boolean
  status?: YaPlusStatusEnum
  ratingError?: string
  plus?: PlusStatusByService[]
  showInfo?: boolean
  isAvailable?: boolean
  contractInfo?: IPlaceContractInfo['services']
}

export enum YaPlusStatusEnum {
  /* Недоступно подключение Плюс */
  CANNOT_BE_ACTIVATED = 'can_not_be_activated',
  /* Доступно подключение Плюс */
  CAN_BE_ACTIVATED = 'can_be_activated',
  /* Создана заявка на подключение Плюса */
  ACTIVATING = 'activating',
  /* Плюс подключен, но не активирован */
  WAIT_TO_START = 'wait_to_start',
  /* Плюс активен */
  ACTIVE = 'active',
  /* Cоздана заявка на отключение Плюс */
  DEACTIVATING = 'deactivating'
}

export interface YaPlusStatus {
  /* Статус подключения Плюса */
  status: YaPlusStatusEnum
  /* Причины отказа подключения Плюса */
  reasons?: YaPlusUnavailableReason[]
  /* Текст с процентом кешбека */
  value?: string
  /* Дата и время начала действия Плюса */
  starts?: string
  /* Дата и время окончания действия Плюса */
  ends?: string
}

export interface PlusStatusByService {
  service: Service
  status_info: YaPlusStatus
}

export interface YaPlusFeatureDTO {
  /** ID ресторана */
  place_id: number
  place_info_by_service?: PlusStatusByService[]
}

export type YaPlusStatusResponse = {
  /** Массив статусов подключения Плюса для ресторанов */
  places: YaPlusFeatureDTO[]
}

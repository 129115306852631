import {useExp3} from '@eda-restapp/configs'
import {metrika} from '@eda-restapp/logger'
import {useMatch} from 'react-router-dom'

import {useModal} from '@restapp/shared-modals'

import {PROMOTION_BONUSES_DRAWER_NAME} from '../../constants'
import {useBonusesBalance} from '../../hooks/useBonusesBalance'
import {useBonusesCampaigns} from '../../hooks/useBonusesCampaigns'

import {BonusesBalance} from './BonusesBalance'

export const BonusesBalanceContainer = () => {
  const promotionConfig = useExp3('restapp_promotion')
  const bonusesBalance = useBonusesBalance()
  const {open} = useModal({modalName: PROMOTION_BONUSES_DRAWER_NAME})

  const matchHome = useMatch('/home')
  const matchPromotion = useMatch('/promotion')

  const handleOpenBonusesDrawer = () => {
    if (matchHome || matchPromotion) {
      metrika({target: 'bonuses_click_open_promo_drawer', params: {place: matchHome ? 'home' : 'promotion'}})
    }
    open()
  }

  const newBonusesCampaigns = useBonusesCampaigns({campaignsStatus: 'new', limit: 1})
  const acceptedBonusesCampaigns = useBonusesCampaigns({campaignsStatus: 'accepted', limit: 1})

  const hasAnyCampaigns =
    (newBonusesCampaigns?.campaigns && newBonusesCampaigns.campaigns.length > 0) ||
    (acceptedBonusesCampaigns?.campaigns && acceptedBonusesCampaigns.campaigns.length > 0)

  const hasBalance = !!bonusesBalance.total && (Number(bonusesBalance.total) > 0 || hasAnyCampaigns)

  if (!hasBalance || !promotionConfig.cpaBonuses.isStartByBonusesAvailable) {
    return null
  }

  return (
    <>
      {!!bonusesBalance.total && (
        <BonusesBalance
          total={bonusesBalance.total}
          balanceToBurn={bonusesBalance.balanceToBurn}
          onOpenBonusesDrawer={handleOpenBonusesDrawer}
        />
      )}
    </>
  )
}

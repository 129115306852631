import FS_SHADER_HIGHP from './shadersHighp/Snowfall.shader.frag'
import VS_SHADER_HIGHP from './shadersHighp/Snowfall.shader.vert'
import FS_SHADER_MEDIUMP from './shadersMediump/Snowfall.shader.frag'
import VS_SHADER_MEDIUMP from './shadersMediump/Snowfall.shader.vert'

type Subscriber = (eventType: string) => void

type ShaderType = WebGLRenderingContextBase['VERTEX_SHADER'] | WebGLRenderingContextBase['FRAGMENT_SHADER']

interface WebGLDrawingProgram extends WebGLProgram {
  in_pos: GLint
  u_time: WebGLUniformLocation | null
  u_resolution: WebGLUniformLocation | null
}

type HighpBufferObject = {
  position?: WebGLBuffer | null
  dimensions?: WebGLBuffer | null
}

type MediumpBufferObject = {
  position?: WebGLBuffer | null
  size?: WebGLBuffer | null
  offset?: WebGLBuffer | null
  wave?: WebGLBuffer | null
}

const SCENE_OBJECT_POSITION = [-1, -1, 1, -1, 1, 1, -1, 1]
const SCENE_OBJECT_DIMENSIONS = [0, 1, 2, 0, 2, 3]

export class SnowfallController {
  private animationPaused: boolean = true
  private canvas?: HTMLCanvasElement
  private glContext?: WebGL2RenderingContext | WebGLRenderingContext | null
  private highpEnabled?: boolean
  private drawingProgram?: WebGLDrawingProgram | null
  private highpBuffer: HighpBufferObject = {}
  private mediumpBuffer: MediumpBufferObject = {}
  private mediumpFlakesCount: number = 600
  private vsShader?: string
  private fsShader?: string
  private vsShaderObject?: WebGLShader
  private fsShaderObject?: WebGLShader
  private subscribers: Subscriber[] = []

  initialize(canvas: HTMLCanvasElement, highpEnabled?: boolean) {
    this.canvas = canvas
    this.glContext = canvas.getContext('webgl2') || canvas.getContext('webgl')

    if (!this.glContext) {
      return this.removeCanvas()
    }

    const vertexHighpSupported = this.isHighpSupported(this.glContext.VERTEX_SHADER)
    const fragmentHighpSupported = this.isHighpSupported(this.glContext.FRAGMENT_SHADER)

    this.highpEnabled = highpEnabled && vertexHighpSupported && fragmentHighpSupported

    this.vsShader = this.highpEnabled ? VS_SHADER_HIGHP : VS_SHADER_MEDIUMP
    this.fsShader = this.highpEnabled ? FS_SHADER_HIGHP : FS_SHADER_MEDIUMP

    this.drawingProgram = this.glContext.createProgram() as WebGLDrawingProgram
  }

  subscribe(cb: Subscriber) {
    this.subscribers.push(cb)
  }

  removeCanvas() {
    this.animationPaused = true
    this.destruct()
    this.subscribers.forEach((cb) => cb('removeCanvas'))
  }

  initScene() {
    if (!this.canvas || !this.glContext || !this.drawingProgram || !this.vsShader || !this.fsShader) {
      return this.removeCanvas()
    }

    try {
      this.vsShaderObject = this.applyShader(this.vsShader, this.glContext.VERTEX_SHADER)
      this.fsShaderObject = this.applyShader(this.fsShader, this.glContext.FRAGMENT_SHADER)
    } catch {
      return this.removeCanvas()
    }

    if (this.highpEnabled) {
      this.highpInitialize()
    } else {
      this.mediumpInitialize()
    }
  }

  destruct() {
    this.animationPaused = true

    if (this.highpEnabled) {
      this.highpDesctruct()
    } else {
      this.mediumpDestruct()
    }

    if (this.vsShaderObject) {
      this.glContext?.deleteShader(this.vsShaderObject)
      this.vsShaderObject = undefined
      this.vsShader = undefined
    }
    if (this.fsShaderObject) {
      this.glContext?.deleteShader(this.fsShaderObject)
      this.fsShaderObject = undefined
      this.fsShader = undefined
    }

    if (this.drawingProgram) {
      this.glContext?.deleteProgram(this.drawingProgram)
      this.drawingProgram = undefined
    }
  }

  canvasResize(width: number, height: number) {
    if (!this.canvas) {
      return this.removeCanvas()
    }

    this.canvas.width = width
    this.canvas.height = height
  }

  private applyShader(source: string, type: ShaderType) {
    if (!this.drawingProgram || !this.glContext) {
      this.removeCanvas()
      return
    }

    const shaderObject = this.glContext.createShader(type)

    if (!shaderObject) {
      this.removeCanvas()
      return
    }

    this.glContext.shaderSource(shaderObject, source)
    this.glContext.compileShader(shaderObject)

    const compileStatus = this.glContext?.getShaderParameter(shaderObject, this.glContext.COMPILE_STATUS) as unknown

    if (!compileStatus) {
      throw new Error(this.glContext.getShaderInfoLog(shaderObject) ?? 'Compile Error')
    }

    this.glContext.attachShader(this.drawingProgram, shaderObject)
    this.glContext.linkProgram(this.drawingProgram)

    return shaderObject
  }

  private isHighpSupported(shaderType: WebGLRenderingContextBase['FRAGMENT_SHADER' | 'VERTEX_SHADER']) {
    if (!this.glContext) {
      return false
    }

    const highFloatPrecisionFormat = this.glContext.getShaderPrecisionFormat(shaderType, this.glContext.HIGH_FLOAT)
    const highIntPrecisionFormat = this.glContext.getShaderPrecisionFormat(shaderType, this.glContext.HIGH_INT)

    return (
      !!highFloatPrecisionFormat &&
      highFloatPrecisionFormat.precision >= 23 &&
      highFloatPrecisionFormat.rangeMax === 127 &&
      highFloatPrecisionFormat.rangeMin === 127 &&
      !!highIntPrecisionFormat &&
      highIntPrecisionFormat.rangeMax >= 30 &&
      highIntPrecisionFormat.rangeMin >= 30
    )
  }

  private highpInitialize() {
    if (
      !this.canvas ||
      !this.glContext ||
      !this.drawingProgram ||
      !this.vsShader ||
      !this.fsShader ||
      !this.highpEnabled
    ) {
      return this.removeCanvas()
    }

    this.drawingProgram.in_pos = this.glContext.getAttribLocation(this.drawingProgram, 'in_pos')
    this.drawingProgram.u_time = this.glContext.getUniformLocation(this.drawingProgram, 'u_time')
    this.drawingProgram.u_resolution = this.glContext.getUniformLocation(this.drawingProgram, 'u_resolution')
    this.glContext.useProgram(this.drawingProgram)

    this.highpBuffer.position = this.glContext.createBuffer()

    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.highpBuffer.position)
    this.glContext.bufferData(
      this.glContext.ARRAY_BUFFER,
      new Float32Array(SCENE_OBJECT_POSITION),
      this.glContext.STATIC_DRAW
    )

    this.highpBuffer.dimensions = this.glContext.createBuffer()

    if (!this.highpBuffer.dimensions) {
      return this.removeCanvas()
    }

    this.glContext.bindBuffer(this.glContext.ELEMENT_ARRAY_BUFFER, this.highpBuffer.dimensions)
    this.glContext.bufferData(
      this.glContext.ELEMENT_ARRAY_BUFFER,
      new Uint16Array(SCENE_OBJECT_DIMENSIONS),
      this.glContext.STATIC_DRAW
    )

    this.glContext.enableVertexAttribArray(this.drawingProgram.in_pos)
    this.glContext.vertexAttribPointer(this.drawingProgram.in_pos, 2, this.glContext.FLOAT, false, 0, 0)

    this.glContext.enable(this.glContext.DEPTH_TEST)
    this.glContext.clearColor(0.0, 0.0, 0.0, 1.0)

    this.startRender()
  }

  private highpDesctruct() {
    if (this.highpBuffer.position) {
      this.glContext?.deleteBuffer(this.highpBuffer.position)
    }
    if (this.highpBuffer.dimensions) {
      this.glContext?.deleteBuffer(this.highpBuffer.dimensions)
    }

    this.highpBuffer = {}
  }

  private startRender() {
    this.animationPaused = false
    const timeMultiplier = this.highpEnabled ? 0.001 : 0.00015

    const render = (delta: number = 0) => {
      if (this.animationPaused) {
        return
      }

      if (!this.canvas || !this.drawingProgram || !this.glContext) {
        return this.removeCanvas()
      }

      this.glContext.viewport(0, 0, this.canvas.width, this.canvas.height)
      this.glContext.clear(this.glContext.COLOR_BUFFER_BIT)

      this.glContext.uniform1f(this.drawingProgram.u_time, (delta * timeMultiplier) % 10000)
      this.glContext.uniform2f(this.drawingProgram.u_resolution, Math.max(2000, this.canvas.width), this.canvas.height)

      if (this.highpEnabled) {
        this.glContext.drawElements(
          this.glContext.TRIANGLES,
          SCENE_OBJECT_DIMENSIONS.length,
          this.glContext.UNSIGNED_SHORT,
          0
        )
      } else {
        this.glContext.drawArrays(this.glContext.POINTS, 0, this.mediumpFlakesCount)
      }

      requestAnimationFrame(render)
    }

    requestAnimationFrame(render)
  }

  private mediumpInitialize() {
    if (!this.canvas || !this.glContext || !this.drawingProgram || !this.vsShader || !this.fsShader) {
      return this.removeCanvas()
    }

    this.drawingProgram.u_time = this.glContext.getUniformLocation(this.drawingProgram, 'u_time')
    this.drawingProgram.u_resolution = this.glContext.getUniformLocation(this.drawingProgram, 'u_resolution')
    this.glContext.useProgram(this.drawingProgram)

    this.mediumpBuffer.position = this.glContext.createBuffer()
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.position)

    this.mediumpBuffer.size = this.glContext.createBuffer()
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.size)

    this.mediumpBuffer.offset = this.glContext.createBuffer()
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.offset)

    this.mediumpBuffer.wave = this.glContext.createBuffer()
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.wave)

    const positions = new Float32Array(this.mediumpFlakesCount * 2)
    const sizes = new Float32Array(this.mediumpFlakesCount)
    const offsets = new Float32Array(this.mediumpFlakesCount)
    const frequencies = new Float32Array(this.mediumpFlakesCount)
    const phases = new Float32Array(this.mediumpFlakesCount)

    for (let i = 0; i < this.mediumpFlakesCount; i++) {
      positions[i * 2] = Math.random()
      positions[i * 2 + 1] = Math.random()
      sizes[i] = Math.random() * 5 + 1 // Рандомизация размера снежинки
      offsets[i] = Math.random() * 0.5 + 0.5 // Скорость
      frequencies[i] = Math.random() * 5 + 5 // Частота волны
      phases[i] = Math.random() * Math.PI * 100 // Фаза волны
    }

    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.position)
    this.glContext.bufferData(this.glContext.ARRAY_BUFFER, positions, this.glContext.STATIC_DRAW)

    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.size)
    this.glContext.bufferData(this.glContext.ARRAY_BUFFER, sizes, this.glContext.STATIC_DRAW)

    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.offset)
    this.glContext.bufferData(this.glContext.ARRAY_BUFFER, offsets, this.glContext.STATIC_DRAW)

    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.wave)
    this.glContext.bufferData(this.glContext.ARRAY_BUFFER, frequencies, this.glContext.STATIC_DRAW)

    const positionLocation = this.glContext.getAttribLocation(this.drawingProgram, 'a_position')
    const sizeLocation = this.glContext.getAttribLocation(this.drawingProgram, 'a_size')
    const offsetLocation = this.glContext.getAttribLocation(this.drawingProgram, 'a_offset')
    const frequencyLocation = this.glContext.getAttribLocation(this.drawingProgram, 'a_frequency')
    const phaseLocation = this.glContext.getAttribLocation(this.drawingProgram, 'a_phase')

    this.glContext.enableVertexAttribArray(positionLocation)
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.position)
    this.glContext.vertexAttribPointer(positionLocation, 2, this.glContext.FLOAT, false, 0, 0)

    this.glContext.enableVertexAttribArray(sizeLocation)
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.size)
    this.glContext.vertexAttribPointer(sizeLocation, 1, this.glContext.FLOAT, false, 0, 0)

    this.glContext.enableVertexAttribArray(offsetLocation)
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.offset)
    this.glContext.vertexAttribPointer(offsetLocation, 1, this.glContext.FLOAT, false, 0, 0)

    this.glContext.enableVertexAttribArray(frequencyLocation)
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.wave)
    this.glContext.vertexAttribPointer(frequencyLocation, 1, this.glContext.FLOAT, false, 0, 0)

    this.glContext.enableVertexAttribArray(phaseLocation)
    this.glContext.bindBuffer(this.glContext.ARRAY_BUFFER, this.mediumpBuffer.wave)
    this.glContext.vertexAttribPointer(phaseLocation, 1, this.glContext.FLOAT, false, 0, 0)

    this.drawingProgram.u_time = this.glContext.getUniformLocation(this.drawingProgram, 'u_time')
    this.drawingProgram.u_resolution = this.glContext.getUniformLocation(this.drawingProgram, 'u_resolution')

    this.startRender()
  }

  private mediumpDestruct() {
    if (this.mediumpBuffer.offset) {
      this.glContext?.deleteBuffer(this.mediumpBuffer.offset)
    }
    if (this.mediumpBuffer.position) {
      this.glContext?.deleteBuffer(this.mediumpBuffer.position)
    }
    if (this.mediumpBuffer.size) {
      this.glContext?.deleteBuffer(this.mediumpBuffer.size)
    }
    if (this.mediumpBuffer.wave) {
      this.glContext?.deleteBuffer(this.mediumpBuffer.wave)
    }

    this.mediumpBuffer = {}
  }
}

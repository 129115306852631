import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {isEqual, difference} from 'lodash-es'

import type {VatInfo} from '@restapp/shared-api/types/massVat'

export const massVatSlice = createSlice({
  name: 'massVat',
  initialState: getInitialState,
  reducers: {
    formUpdateSelectedPlaces: (state, action: PayloadAction<string[]>) => {
      const removed = difference(Object.keys(state.form.current), action.payload)
      const added = difference(action.payload, Object.keys(state.form.current))

      removed.forEach((id) => {
        delete state.form.current[id]
        state.form.sortOrder = state.form.sortOrder.filter((sortingId) => sortingId !== id)
      })

      added.forEach((id) => {
        state.form.current[id] = {}
        state.form.sortOrder.unshift(id)
        if (state.form.planned[id]) {
          // восстанавливаем запланированные ранее значения
          state.form.current[id].vat = state.form.planned[id].vat
          state.form.current[id].applyAt = state.form.planned[id].applyAt
        }
      })
    },
    formInit: (state, action: PayloadAction<VatInfo[]>) => {
      state.drawerReady = true
      state.form = getInitialState().form

      const onlyPlanned: FormState = {}
      const fromContextPlace: FormState = {}

      action.payload.forEach((place) => {
        // прикапываем все доступные для выбора НДС
        // они пригодятся для логики показа формы и при добавлении через селектор позже
        state.form.vatOptionsByPlace[place.place_id] =
          place.available_vat_list
            ?.map((option) => {
              return {
                label: option.description,
                value: option.vat
              }
            })
            .sort((a, b) => a.value - b.value) ?? [] // сортируем чтобы при сравнениях можно было сравнивать по индексно

        // запланированными считаются только next_month
        if (place.planned?.type === 'next_month') {
          onlyPlanned[place.place_id] = {
            vat: place.planned.vat,
            applyAt: 'nextMonth'
          }
        }

        if (state.contextPlaceIds.includes(place.place_id)) {
          fromContextPlace[place.place_id] = {}
        }
      })

      // инициализируемся с одним из вариантов
      // - только запланированные если они есть
      // - только те которые нам передали при инициализации в контексте (н.п кликнули в разделе конкретного ресторана)
      // - ничего, юзер сам выбирает через селектор
      if (Object.keys(onlyPlanned).length > 0) {
        state.form.initial = onlyPlanned
      } else if (Object.keys(fromContextPlace).length > 0) {
        state.form.initial = fromContextPlace
      } else {
        state.form.initial = {}
      }

      state.form.planned = onlyPlanned
      state.form.sortOrder = Object.keys(state.form.initial)
      state.form.current = state.form.initial
      state.form.vatSectionType = selectIsAllCurrentEqual(state) ? 'common' : 'individual'
    },
    formVatSectionTypeUpdate: (state, action: PayloadAction<VatSectionType>) => {
      const isAllCurrentEqual = selectIsAllCurrentEqual(state)

      if (action.payload === 'common' && !isAllCurrentEqual) {
        state.commonTypeChangeOpened = true
        return
      }

      state.form.vatSectionType = action.payload
    },
    formVatUpdateAll: (state, action: PayloadAction<number>) => {
      Object.values(state.form.current).forEach((place) => {
        place.vat = action.payload
      })
    },
    formApplyAtUpdateAll: (state, action: PayloadAction<VatSectionApplyAt>) => {
      Object.values(state.form.current).forEach((place) => {
        place.applyAt = action.payload
      })
    },
    formVatUpdate: (state, action: PayloadAction<{placeId: string; vat: number}>) => {
      const placeState = state.form.current[action.payload.placeId]
      if (!placeState) {
        return
      }

      placeState.vat = action.payload.vat
    },
    formApplyAtUpdate: (state, action: PayloadAction<{placeId: string; applyAt: VatSectionApplyAt}>) => {
      const placeState = state.form.current[action.payload.placeId]
      if (!placeState) {
        return
      }

      placeState.applyAt = action.payload.applyAt
    },
    formFinishApply: (state) => {
      state.drawerOpened = false
      const plannedTypes = Array.from(new Set(selectFormValues(state).map((item) => mapApplyAt(item.type))))

      if (plannedTypes.length > 0) {
        state.successDialog.messages = plannedTypes
        state.successDialog.opened = true
      }
    },
    formFinishCancel: (state) => {
      state.drawerOpened = false
    },
    openDrawer: (state, action: PayloadAction<MassVatState['contextPlaceIds'] | undefined>) => {
      Object.assign(state, getInitialState())
      state.contextPlaceIds = action.payload || []
      state.drawerOpened = true
    },
    closeDrawer: (state) => {
      const isDirty = selectFormIsDirty(state)

      if (isDirty) {
        state.closeConfirmOpened = true
        return
      }

      state.drawerOpened = false
    },
    commonTypeChangeConfirm: (state) => {
      state.commonTypeChangeOpened = false
      state.form.vatSectionType = 'common'

      Object.values(state.form.current).forEach((place) => {
        delete place.applyAt
        delete place.vat
      })
    },
    commonTypeChangeAbort: (state) => {
      state.commonTypeChangeOpened = false
    },
    closeDrawerAbort: (state) => {
      state.closeConfirmOpened = false
    },
    closeDrawerConfirm: (state) => {
      state.closeConfirmOpened = false
      state.drawerOpened = false
    },
    askCancelConfirm: (state) => {
      state.cancelConfirmOpened = true
    },
    cancelConfirm: () => {
      // noop
    },
    cancelAbort: (state) => {
      state.cancelConfirmOpened = false
    },
    closeSuccessDialog: (state) => {
      state.successDialog.opened = false
    }
  },
  extraReducers: (builder) => {
    // следим за тем чтобы common был невозможен если не все current равны между собой
    builder.addMatcher(
      isAnyOf(
        massVatSlice.actions.formApplyAtUpdate,
        massVatSlice.actions.formVatUpdate,
        massVatSlice.actions.formVatUpdateAll,
        massVatSlice.actions.formApplyAtUpdateAll,
        massVatSlice.actions.formUpdateSelectedPlaces
      ),
      (state) => {
        if (!selectIsAllCurrentEqual(state)) {
          state.form.vatSectionType = 'individual'
        }
      }
    )
  },
  selectors: {
    selectPlannedCancelIds: (state) => difference(Object.keys(state.form.planned), Object.keys(state.form.current)),
    selectFormPlaceIds: (state) => Object.keys(state.form.current),
    selectDrawerContext: (state) => state.contextPlaceIds,
    selectDrawerReady: (state) => state.drawerReady,
    selectFormVatSectionType: (state) => {
      const currentPlaces = Object.keys(state.form.current)
      const notEnoughPlaces = currentPlaces.length < 2

      const allPlacesHasOptions = currentPlaces.every((placeId) => !!state.form.vatOptionsByPlace[placeId]?.length)

      const allPlacesVatOptions = currentPlaces
        .map((placeId) => state.form.vatOptionsByPlace[placeId])
        .filter((v) => !!v)
      const isAllVatOptionsEqual = allPlacesVatOptions.every((options) => isEqual(allPlacesVatOptions[0], options))

      if (notEnoughPlaces || !allPlacesHasOptions || !isAllVatOptionsEqual) {
        return 'none'
      }

      return state.form.vatSectionType
    },
    selectFormValues,
    selectFormState: (state) => {
      return state.form.sortOrder.map((id) => {
        return {...state.form.current[id], vatOptions: state.form.vatOptionsByPlace[id] || [], placeId: id}
      })
    },
    selectApplyButtonEnabled: (state) => {
      const isDirty = selectFormIsDirty(state)
      const isAllValid = Object.values(state.form.current).every(isValidItem)

      return isDirty && isAllValid
    },
    selectDrawerOpened: (state) => state.drawerOpened,
    selectPlannedIds,
    selectCancelButtonVisible: (state) => {
      return selectPlannedIds(state).length > 0
    },
    selectCancelConfirmDialogOpened: (state) => state.drawerOpened && state.cancelConfirmOpened,
    selectCommonChangeDialogOpened: (state) => state.drawerOpened && state.commonTypeChangeOpened,
    selectCloseConfirmDialogOpened: (state) => state.drawerOpened && state.closeConfirmOpened,
    selectSuccessDialogOpen: (state) => state.successDialog.opened,
    selectSuccessDialogMessages: (state) => state.successDialog.messages
  }
})

/** Подготавливает данные которые отправим в ручку */
function selectFormValues(state: MassVatState) {
  return Object.entries(state.form.current)
    .map(([placeId, place]) => {
      if (isValidItem(place)) {
        return {
          place_id: placeId,
          vat: place.vat,
          type: mapApplyAt(place.applyAt)
        }
      }
    })
    .filter((v) => !!v)
}

function selectPlannedIds(state: MassVatState) {
  return Object.keys(state.form.planned)
}

function selectIsAllCurrentEqual(state: MassVatState) {
  const allCurrent = Object.values(state.form.current)
  return allCurrent.every((entry) => entry.vat === allCurrent[0]?.vat && entry.applyAt === allCurrent[0]?.applyAt)
}

function selectFormIsDirty(state: MassVatState) {
  const isPristine = isEqual(state.form.initial, state.form.current)
  return !isPristine
}

function isValidItem(item: FormState[string]): item is Required<typeof item> {
  return item.vat !== undefined && item.applyAt !== undefined
}

function mapApplyAt(key: 'asap' | 'nextMonth'): 'now' | 'next_month'
function mapApplyAt(key: 'now' | 'next_month'): 'asap' | 'nextMonth'
function mapApplyAt(key: 'asap' | 'nextMonth' | 'now' | 'next_month') {
  switch (key) {
    case 'asap':
      return 'now'
    case 'now':
      return 'asap'
    case 'nextMonth':
      return 'next_month'
    case 'next_month':
      return 'nextMonth'

    default:
      throw new TypeError("Unknown key value, expected ['asap' | 'nextMonth' | 'now' | 'next_month']")
  }
}

function getInitialState(): MassVatState {
  return {
    contextPlaceIds: [],
    form: {
      vatSectionType: 'individual',
      vatOptionsByPlace: {},
      sortOrder: [],
      planned: {},
      initial: {},
      current: {}
    },
    drawerReady: false,
    drawerOpened: false,
    commonTypeChangeOpened: false,
    closeConfirmOpened: false,
    cancelConfirmOpened: false,
    successDialog: {
      opened: false,
      messages: []
    }
  }
}

type MassVatState = {
  contextPlaceIds: string[]
  form: {
    sortOrder: string[]
    vatSectionType: VatSectionType
    vatOptionsByPlace: {
      [placeId: string]: VatOption[]
    }
    planned: FormState
    initial: FormState
    current: FormState
  }
  drawerReady: boolean
  drawerOpened: boolean
  closeConfirmOpened: boolean
  cancelConfirmOpened: boolean
  commonTypeChangeOpened: boolean
  successDialog: {
    opened: boolean
    messages: VatSectionApplyAt[]
  }
}

type FormState = {
  [placeId: string]: {
    vat?: number
    applyAt?: VatSectionApplyAt
  }
}

type VatOption = {label: string; value: number}
type VatSectionType = 'individual' | 'common'
type VatSectionApplyAt = 'asap' | 'nextMonth'

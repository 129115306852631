import type {FC} from 'react'
import useLocalStorageState from 'use-local-storage-state'

import {PassedErrorBoundary} from './PassedErrorBoundary'
import {Snowfall} from './Snowfall'
import {WAS_SHOWN_LOCAL_STORAGE_KEY} from './Snowfall.constants'

type SnowfallContainerProps = {
  anchorEl: HTMLElement | null
  isVisible: boolean
}

export const SnowfallContainer: FC<SnowfallContainerProps> = ({anchorEl, isVisible}) => {
  const [wasShownSnowfall] = useLocalStorageState<boolean>(WAS_SHOWN_LOCAL_STORAGE_KEY)

  if (wasShownSnowfall || !isVisible) {
    return null
  }

  return (
    <PassedErrorBoundary>
      <Snowfall anchorEl={anchorEl} />
    </PassedErrorBoundary>
  )
}

import {Snackbar} from '@eda-restapp/snackbars'
import React, {type FC} from 'react'

import {useActiveOrders} from '@restapp/orders'
import type {FullPlaceType} from '@restapp/shared-api'

import {useDisablingParams} from '../../../../hooks/useDisablingParams'

import {DisablePlaceWithOrdersModalContainer} from './components/DisablePlaceWithOrdersModal/DisablePlaceWithOrdersModal.container'
import {DisablePlaceWithoutOrdersModalContainer} from './components/DisablePlaceWithoutOrdersModal/DisablePlaceWithoutOrdersModal.container'

type DisablePlaceModalContainerProps = {
  isOpen: boolean
  place?: FullPlaceType
  onCloseClick: () => void
}

export const DisablePlaceModalContainer: FC<DisablePlaceModalContainerProps> = ({isOpen, place, onCloseClick}) => {
  const activeOrdersQuery = useActiveOrders({select: (data) => data.orders})

  const disablingParamsQuery = useDisablingParams(place?.id ?? 0, {
    enabled: isOpen,
    onError: (err) => {
      Snackbar.error({
        text: err.responseData?.message || err.message,
        errorCode: err.traceId
      })

      onCloseClick()
    }
  })

  const placeAcceptedOrdersCount = activeOrdersQuery.data?.filter(
    (order) => order.place_id === place?.id && ['accepted', 'in_delivery'].includes(order.status)
  ).length
  const hasActiveOrders = !!placeAcceptedOrdersCount && placeAcceptedOrdersCount > 0
  const hasActiveOrdersOfFastFood = hasActiveOrders || place?.is_fast_food

  if (!isOpen || !place || !disablingParamsQuery.data) {
    return null
  }

  return hasActiveOrdersOfFastFood ? (
    <DisablePlaceWithOrdersModalContainer
      canCancelOrders={disablingParamsQuery.data?.can_cancel_orders}
      disableReasons={disablingParamsQuery.data?.reasons}
      place={place}
      placeTimezone={disablingParamsQuery.data?.time_zone}
      onCloseClick={onCloseClick}
    />
  ) : (
    <DisablePlaceWithoutOrdersModalContainer
      disableReasons={disablingParamsQuery.data?.reasons}
      place={place}
      placeTimezone={disablingParamsQuery.data?.time_zone}
      onCloseClick={onCloseClick}
    />
  )
}

import {makeStyles} from 'tss-react/mui'

export const useSidebarStyles = makeStyles()((theme) => {
  return {
    wrapper: {
      minHeight: 'fit-content'
    },
    navList: {
      width: '100%',
      rowGap: 4
    },
    separator: {
      padding: '21px 8px 9px 8px',
      fontWeight: 700,
      fontSize: 10,
      lineHeight: 1,
      letterSpacing: '0.08em',
      color: '#999C9E',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    separatorMini: {
      height: 1,
      fontSize: 0,
      backgroundColor: '#57595C',
      padding: 0,
      margin: '19.5px 0'
    },
    feedback: {
      marginBottom: 8
    },
    menu: {
      margin: 0,
      padding: 0,
      flex: '1 1 auto'
    },
    menuInner: {
      overflowX: 'hidden'
    },
    styledScrollbar: {
      scrollbarWidth: 'thin',
      scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
      '&::-webkit-scrollbar': {
        width: '6px'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: 5
      }
    },
    miniSidebarScroll: {
      '&::-webkit-scrollbar': {
        width: '3px'
      }
    },
    head: {
      display: 'flex',
      height: 48,
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0
    },
    headWhenOpen: {
      gap: 15
    },
    logo: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left 50%',
      backgroundSize: 'contain',
      width: '100%',
      height: 24,
      display: 'block',
      marginLeft: 12,
      pointerEvents: 'none'
    },
    newYearLogoContainer: {
      height: 28,
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      pointerEvents: 'none',
      transition: 'width .25s linear',
      width: 0,

      [theme.breakpoints.up('md')]: {
        height: 24
      }
    },
    newYearLogoContainerVisible: {
      width: '100%',
      marginLeft: 12
    },
    newYearLogo: {
      maxHeight: '100%',
      maxWidth: '100%'
    },
    toggleChevron: {
      background: '#1F2021',
      color: '#999C9E',
      borderRadius: '50%',
      cursor: 'pointer'
    },
    rotate180: {
      transform: 'rotateY(180deg)'
    },
    relativeRoot: {
      position: 'relative',
      overflow: 'hidden'
    },
    drawerPaper: {
      position: 'relative',
      width: 302,
      display: 'flex',
      background: '#292B31',
      borderRadius: '0px 20px 20px 0px',
      padding: '12px 2px 12px 8px',
      transform: 'translateX(0px)',
      transition: 'width .25s ease-out',
      overflowY: 'scroll',
      overflowX: 'hidden',

      [theme.breakpoints.up('md')]: {
        width: 240
      }
    },
    drawerPaperSquare: {
      borderRadius: 0
    },
    drawerPaperMini: {
      padding: '12px 5px 12px 8px',
      width: '64px !important'
    },
    drawerPaperHidden: {
      opacity: 0,
      transform: 'translateX(-40px)',
      overflowX: 'hidden',
      width: 0,
      padding: 0
    },
    nameWrapper: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      padding: 8,
      color: '#999C9E',
      borderRadius: 12,

      '&:hover': {
        color: '#ffffff',
        background: '#202127'
      }
    },
    versionBlock: {
      height: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      fontSize: 13,
      color: '#9E9B98',
      opacity: 0.7
    },
    versionBlockMini: {
      order: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
      paddingTop: '10px',
      textAlign: 'center'
    },
    tooltip: {
      padding: '3px 8px',
      fontSize: 14
    },
    laguageSelectorHoverButton: {
      '&:hover, &:active': {
        color: '#FFFFFF'
      }
    },
    languageSelectorMenu: {
      '& .MuiMenu-paper': {
        marginTop: -8,

        [theme.breakpoints.up('md')]: {
          margin: '0 0 0 27px'
        }
      }
    },
    drawer: {
      ['@media print']: {
        display: 'none'
      }
    },
    newYearDrawerPaper: {
      background: '#1B1D32',
      borderRight: 0,
      padding: '12px 8px',

      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: '0px'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent'
      }
    },
    newYearHead: {
      marginTop: 35,

      [theme.breakpoints.up('md')]: {
        marginTop: 10
      }
    },
    newYearChevronContainer: {
      position: 'absolute',
      top: 58,

      [theme.breakpoints.up('md')]: {
        top: 44
      }
    },
    newYearToggleChevron: {
      background: '#10111E',
      zIndex: 1
    },
    newYearLanguageSelectorButton: {
      backgroundColor: '#1B1D32 !important',

      '&:hover': {
        background: '#10111E !important',
        color: '#ffffff !important'
      }
    }
  }
})

import {useExp3} from '@eda-restapp/configs'
import {useMemo} from 'react'

import {usePlaces} from '@restapp/core-places'

import {useHolidayAlertConfig} from './useHolidayAlertConfig'

const HOLIDAY_SCHEDULE_DISABLE_REASON = 103

/**
 * Алерт об отключенных плейсах из-за незаполненного новогоднего расписания
 * Имеет возможность вернуть алерт только если он включен в конфиге restapp_new_year
 * */
export const useNewYearScheduleAlerts = () => {
  const newYearConfig = useExp3('restapp_new_year')
  const holidayAlertConfig = useHolidayAlertConfig()
  const placesQuery = usePlaces({enabled: !!newYearConfig.alertHolidayScheduleEnabled})

  const hasNewYearHolidayBlocks = useMemo(() => {
    if (!newYearConfig.alertHolidayScheduleEnabled || placesQuery.isLoading) {
      return
    }

    for (const place of placesQuery.places) {
      if (!place.disable_details) {
        continue
      }

      if (place.disable_details.reason === HOLIDAY_SCHEDULE_DISABLE_REASON) {
        return true
      }
    }

    return false
  }, [newYearConfig.alertHolidayScheduleEnabled, placesQuery.isLoading, placesQuery.places])

  if (!newYearConfig.alertHolidayScheduleEnabled || placesQuery.isLoading || !hasNewYearHolidayBlocks) {
    return {
      data: [],
      isLoading: placesQuery.isLoading
    }
  }

  return {
    data: [holidayAlertConfig],
    isLoading: placesQuery.isLoading
  }
}

import type {FullPlaceType} from '@restapp/shared-api'

import type {MinMaxDefaults} from '../../types/common'
import type {RatesSection} from '../../types/rates'

const EMPTY_SECTION = {
  adv_price: undefined,
  adv_budget: undefined,
  banner_budget: undefined,
  banner_price: undefined
}

const mergeMinMaxDefaults = (
  acc: MinMaxDefaults | undefined,
  item: MinMaxDefaults | undefined
): MinMaxDefaults | undefined => {
  if (!item) {
    return acc
  }
  const minMax = {
    min: item.min !== undefined && acc?.min !== undefined ? Math.max(acc.min, item.min) : (item.min ?? acc?.min),
    max: item.max !== undefined && acc?.max !== undefined ? Math.min(acc.max, item.max) : (item.max ?? acc?.max)
  }

  return {
    ...minMax,
    default:
      item.default !== undefined && acc?.default !== undefined
        ? Math.min(Math.max(acc.default, item.default, minMax.min ?? -Infinity), minMax.max ?? Infinity)
        : (item.default ?? acc?.default)
  }
}

const countryCheck =
  (place: FullPlaceType) =>
  ({country_code}: RatesSection) =>
    country_code === place.country_code
const regionCheck =
  (place: FullPlaceType) =>
  ({region_slug}: RatesSection) =>
    region_slug === place.region_slug

const mergeSections =
  (check: typeof countryCheck | typeof regionCheck) => (rates: RatesSection[], places: FullPlaceType[]) =>
    places.reduce<RatesSection>((acc, place) => {
      const rate = rates?.find(check(place))

      if (!rate) {
        return acc
      }

      return {
        adv_price: mergeMinMaxDefaults(acc.adv_price, rate.adv_price),
        adv_budget: mergeMinMaxDefaults(acc.adv_budget, rate.adv_budget),
        banner_budget: mergeMinMaxDefaults(acc.banner_budget, rate.banner_budget),
        banner_price: mergeMinMaxDefaults(acc.banner_price, rate.banner_price),
        cpa_budget: mergeMinMaxDefaults(acc.cpa_budget, rate.cpa_budget)
      }
    }, EMPTY_SECTION)

export default {mergeCountryRates: mergeSections(countryCheck), mergeRegionRates: mergeSections(regionCheck)}

import {useExp3} from '@eda-restapp/configs'
import {placesChannel} from '@eda-restapp/microfrontend'
import moment from 'moment-timezone'

import {useApiMutation} from '@restapp/core-api'
import {RESTAURANT_DISABLE_REASONS} from '@restapp/shared'
import type {PlaceDisablingReasonCode} from '@restapp/shared-api'

type DisablePlaceParams = {
  availableAt: moment.Moment | null
  cancelOrders?: boolean
  duration?: number
  placeDisableMinutes?: number
  placeTimezone?: string
  reasonCode?: PlaceDisablingReasonCode
}

type Options = {
  onSuccess?: () => void
  onError?: () => void
  onSettled?: () => void
}

const DEFAULT_PLACE_DISABLE_MINUTES = 30
// 4: "Загрузка ресторана: выходные, мероприятия, ЧП"
const DEFAULT_REASON = RESTAURANT_DISABLE_REASONS.HOLIDAYS

export const useDisablePlace = (placeId: number, opt?: Options) => {
  const restappPlaceConfig = useExp3('restapp_place')

  const {mutate: oldMutate, isLoading: oldIsLoading} = useApiMutation(
    {
      url: '/4.0/restapp-front/places/v3/disable',
      method: 'POST',
      params: {place_id: placeId}
    },
    {
      affectedQueryKeys: [
        ['/4.0/restapp-front/places/v1/suggest', 'GET' as never],
        ['/4.0/restapp-front/places/v2/search', 'GET' as never]
      ],
      onSuccess: opt?.onSuccess,
      onError: opt?.onError,
      onSettled: () => {
        opt?.onSettled?.()
        void placesChannel.broadcast({type: 'refresh'})
      }
    }
  )

  const {mutate, isLoading} = useApiMutation(
    {
      url: '/4.0/restapp-front/places/v2/disable',
      method: 'POST',
      params: {place_id: placeId}
    },
    {
      affectedQueryKeys: [
        ['/4.0/restapp-front/places/v1/suggest', 'GET' as never],
        ['/4.0/restapp-front/places/v2/search', 'GET' as never]
      ],
      onSuccess: opt?.onSuccess,
      onError: opt?.onError,
      onSettled: () => {
        opt?.onSettled?.()
        void placesChannel.broadcast({type: 'refresh'})
      }
    }
  )

  const disablePlace = ({
    availableAt,
    cancelOrders = false,
    reasonCode = DEFAULT_REASON,
    placeTimezone = moment.tz.guess()
  }: DisablePlaceParams) => {
    const placeCurrentTime = moment().tz(placeTimezone)

    // TODO: Remove old-mutate after switching back to v2-handle
    return restappPlaceConfig.newRestaurantDisablingHandle
      ? mutate({
          body: {
            reason_code: reasonCode,
            available_at: (availableAt ?? placeCurrentTime.add(DEFAULT_PLACE_DISABLE_MINUTES, 'minutes')).format(),
            orders_cancel: cancelOrders
          }
        })
      : oldMutate({
          body: {
            reason_code: reasonCode,
            duration: availableAt ? availableAt.diff(placeCurrentTime, 'minutes') : DEFAULT_PLACE_DISABLE_MINUTES,
            orders_cancel: cancelOrders
          }
        })
  }

  return {
    disablePlace,
    isLoading: restappPlaceConfig.newRestaurantDisablingHandle ? isLoading : oldIsLoading
  }
}

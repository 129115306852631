import {useI18n} from '@eda-restapp/i18n'

import type {GlobalAlertType} from '@restapp/shared-api'

export const useHolidayAlertConfig = () => {
  const {t} = useI18n()

  const alertConfig: GlobalAlertType = {
    id: 'new_year_schedule_alert',
    text: t('global_alert.holiday_schedule_blocked.title', 'Установите праздничное расписание'),
    extra: {},
    button: {
      text: t('global_alert.holiday_schedule_blocked.button-text', 'Заполнить расписание'),
      text_color: '#FFFFFF',
      action_data: '/schedule',
      action_type: 'link',
      background_color: '#333333'
    },
    can_close: true,
    text_color: '#FFFFFF',
    background_color: '#000000',
    replay_timeout_minutes: 120
  }

  return alertConfig
}

import {useI18n} from '@eda-restapp/i18n'

import {PLACE_STATUS} from '@restapp/main-places/types/status'
import type {FullPlaceType} from '@restapp/shared-api'
import {getAutostopFinishTime, getPlaceEnabledHumanDate} from '@restapp/shared-places'
import {usePlaceStatus} from '@restapp/shared-places/hooks/usePlaceStatus'

export const usePlaceStatusWithErrorLabel = (place: FullPlaceType) => {
  const placeStatus = usePlaceStatus(place)
  const {t} = useI18n()

  if (!place) {
    return
  }

  switch (placeStatus) {
    case PLACE_STATUS.DISABLED_BY_AUTOSTOP:
      return {
        text:
          getAutostopFinishTime(place.disable_details?.available_at) || t('main-places.utils.otklyuchen', 'Отключён'),
        error: true
      }
    default:
      return {
        text:
          (place.disable_details?.available_at && getPlaceEnabledHumanDate(place.disable_details.available_at)) ||
          t('main-places.utils.otklyuchen', 'Отключён'),
        error: true
      }
  }
}

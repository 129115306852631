import {useExp3} from '@eda-restapp/configs'
import {Box, Error, Typography, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import type {FC} from 'react'

import {PlaceActionContainer} from '@restapp/core-legacy/common/components/Places/PlaceAction'
import type {FullPlaceType} from '@restapp/shared-api'
import {BusyModeButtonSmall} from '@restapp/shared-places'
import {DeliveryIcon} from '@restapp/shared-ui'

import {usePlaceCardStatusLabel} from '../../../../hooks/usePlaceCardStatusLabel'
import {usePlaceStatus} from '../../../../hooks/usePlaceStatus'

import styles from './PlaceStatusListContentItem.module.css'

type PlaceStatusListContentItemProps = {
  place: FullPlaceType
  onBusyModeButtonClick: (placeId: number) => void
  onRefetch: () => Promise<void>
}

export const PlaceStatusListContentItem: FC<PlaceStatusListContentItemProps> = ({
  place,
  onBusyModeButtonClick,
  onRefetch
}) => {
  const placeConfig = useExp3('restapp_place')
  const placeBlocksConfig = useExp3('restapp_place_blocks')
  const placeBlockStatusInfo = usePlaceCardStatusLabel({place})
  const placeStatus = usePlaceStatus(place)
  const isMobile = useIsMobileStrict()

  const isDisabledPlaceLabel = placeBlocksConfig.minorRestaurantSelectPlaceLabelColorStatusesList.includes(placeStatus)
  const placeBlockFinishDate = place.blocks?.[0].finished_at
  const isCurrentlyBlocked = moment(placeBlockFinishDate).isSameOrAfter(moment())

  return (
    <li data-testid={`restaurant-list-item-${place.name}`} className={styles.root} key={place.id}>
      <Box className={styles.mainRow} alignItems='center' gap='s'>
        <DeliveryIcon place={place} />
        <Box flex={1} flexDirection='column'>
          <Typography color={isDisabledPlaceLabel ? 'minor' : undefined} thickness='bold' variant='body2'>
            {place.name}
          </Typography>
          <Typography color={isDisabledPlaceLabel ? 'minor' : undefined} variant='caption1'>
            {place.address}
          </Typography>
        </Box>
        <Box ml={isMobile ? 's' : 'm'}>
          <PlaceActionContainer
            place={place}
            isHideDisableButton={placeConfig.hide_disable_button}
            smallDisableButtonMobile
          />
        </Box>
        {place.is_available && place.busy_mode?.available && (
          <BusyModeButtonSmall
            isActive={place.busy_mode.enabled}
            activeUntil={moment(place.busy_mode.valid_until)}
            onClick={() => onBusyModeButtonClick(place.id)}
            onFinish={onRefetch}
          />
        )}
      </Box>
      {!!placeBlockStatusInfo?.text && (
        <Box
          className={cn(styles.hint, placeBlockStatusInfo.error && styles.hintError)}
          alignItems='center'
          gap='xs'
          ml='xl'
        >
          <Typography lineHeight='normal' variant='caption1'>
            {placeBlockStatusInfo.text}
          </Typography>
          {placeConfig.isNewGroupedPlaceRendering &&
            !!place.blocks?.[0]?.reason &&
            placeBlocksConfig.placeErrorIconDisplayingBlockTypesList.includes(place.blocks[0].reason) &&
            isCurrentlyBlocked &&
            placeBlockStatusInfo.error && <Error fontSize={16} />}
        </Box>
      )}
    </li>
  )
}

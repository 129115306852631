import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import {Box, Typography, Text, LegacyButton as Button} from '@eda-restapp/ui'
import cn from 'classnames'
import React, {type FC, useMemo, useState} from 'react'

import {useSupportChat} from '@restapp/main-chats'
import type {Service, PlacePlus} from '@restapp/shared-api'
import {YaPlusStatusEnum} from '@restapp/shared-api'
import {Dialog} from '@restapp/shared-modals'

import {useYaPlusDisable} from '../../../hooks/useYaPlusDisable'
import {OperatorInfoTooltip} from '../OperatorInfoTooltip/OperatorInfoTooltip'
import PlusServiceSelector from '../PlusServiceSelector'

import ActivePlusConditions from './ActivePlusConditions'
import styles from './ConnectFirstTimeCondition.module.css'
import DisablePlusQuiz from './DisablePlusQuiz'
import {Header} from './Header'
import LearnMoreAboutPlus from './LearnMoreAboutPlus'

export type ConnectFirstTimeConditionProps = {
  drawerPlaces?: PlacePlus[]
  initialServices?: Service[]
  selectedPlaces: number[]
  selectedServices: Service[]
  isUnavailable?: boolean
  isOneFullContractPlace?: boolean
  isDisabledState: boolean
  isLoading: boolean
  isFetchLoading: boolean
  isDesktop: boolean
  isOneAvailableUnitedPlace?: boolean
  isSingleServicePlaces: boolean
  canManagePlus: boolean
  setSelectedPlaces: (places: number[]) => void
  setSelectedServices: (services: Service[]) => void
  setIsConnectMorePlacesToPlus: (value: boolean) => void
  enablePlus: (values: number[], services: Service[]) => void
}

type IDisableConfirmationData = {id: number; services: Service[]}

export const ConnectFirstTimeCondition: FC<ConnectFirstTimeConditionProps> = ({
  drawerPlaces,
  initialServices,
  selectedPlaces,
  selectedServices,
  isUnavailable,
  isOneFullContractPlace,
  isDisabledState,
  isLoading,
  isDesktop,
  isFetchLoading,
  isOneAvailableUnitedPlace,
  isSingleServicePlaces,
  setSelectedPlaces,
  setSelectedServices,
  setIsConnectMorePlacesToPlus,
  enablePlus,
  canManagePlus
}) => {
  const {t} = useI18n()
  const {openNewSupportChat} = useSupportChat()

  const [enablePlusTooltipAnchorEl, setEnablePlusTooltipAnchorEl] = useState<HTMLElement | null>(null)

  const {showDisablePlusQuiz, canDisablePlaceAutomatically, supportChatToPlusPath} = useExp3('restapp_plus')

  const {disablePlus} = useYaPlusDisable()

  const [isOpenDisablePlusQuiz, setIsOpenDisablePlusQuiz] = useState(false)
  const [plusDisableConfirmationData, setPlusDisableConfirmationData] = useState<IDisableConfirmationData>()

  const closePlusDisableConfirmation = () => {
    setPlusDisableConfirmationData(undefined)

    if (showDisablePlusQuiz) {
      setIsOpenDisablePlusQuiz(true)
    }
  }

  const handleSubmitDisablePlus = () => {
    eventLogger({name: 'plus-drawer-disable-plus', additional: {plusDisableConfirmationData}})

    if (plusDisableConfirmationData) {
      if (canDisablePlaceAutomatically) {
        disablePlus(
          [
            {
              place_id: plusDisableConfirmationData.id,
              services: plusDisableConfirmationData?.services
            }
          ],
          closePlusDisableConfirmation
        )

        return
      }

      openNewSupportChat({place_id: plusDisableConfirmationData.id, path: supportChatToPlusPath})
    }
  }

  const activePlaces = useMemo(
    () =>
      drawerPlaces?.filter((place) =>
        place.plus?.some(
          (plus) =>
            plus.status_info.status === YaPlusStatusEnum.ACTIVE ||
            plus.status_info.status === YaPlusStatusEnum.ACTIVATING ||
            plus.status_info.status === YaPlusStatusEnum.WAIT_TO_START ||
            plus.status_info.status === YaPlusStatusEnum.DEACTIVATING
        )
      ),
    [drawerPlaces]
  )

  const hasActivePlace = activePlaces && activePlaces.length > 0

  const notActivePlaces = useMemo(
    () =>
      drawerPlaces
        ?.filter((place) =>
          place.plus?.find(
            (item) =>
              item.status_info.status === YaPlusStatusEnum.CAN_BE_ACTIVATED ||
              item.status_info.status === YaPlusStatusEnum.CANNOT_BE_ACTIVATED
          )
        )
        .sort((a, b) => Number(b.isAvailable) - Number(a.isAvailable)),
    [drawerPlaces]
  )

  return (
    <>
      <Box flexDirection='column' className={styles.container}>
        <Box flexDirection='column' flex={1}>
          <Header
            isUnavailable={isUnavailable}
            initialServices={initialServices}
            drawerPlaces={drawerPlaces}
            notActivePlaces={notActivePlaces}
            isDesktop={isDesktop}
            activePlacesLength={activePlaces?.length || 0}
            isLoading={isLoading}
            className={styles.block}
          />

          {hasActivePlace && (
            <Box flexDirection='column' px='l' py='m' className={styles.block}>
              <ActivePlusConditions
                places={drawerPlaces}
                onDisable={(id, services) => setPlusDisableConfirmationData({id, services})}
                enablePlus={enablePlus}
                setIsModalOpen={setIsConnectMorePlacesToPlus}
                setSelectedServices={setSelectedServices}
              />
            </Box>
          )}

          {notActivePlaces &&
            !hasActivePlace &&
            drawerPlaces &&
            (drawerPlaces.length > 1 || (isOneAvailableUnitedPlace && !isOneFullContractPlace)) && (
              <Box flexDirection='column' px='l' py='m' className={styles.block}>
                <PlusServiceSelector
                  places={notActivePlaces}
                  services={selectedServices}
                  setServices={setSelectedServices}
                  selectedPlaces={selectedPlaces}
                  setSelectedPlaces={setSelectedPlaces}
                  isOneAvailableUnitedPlace={isOneAvailableUnitedPlace}
                  isSingleServicePlaces={isSingleServicePlaces}
                />
              </Box>
            )}

          <LearnMoreAboutPlus className={styles.block} flex={1} />
        </Box>
      </Box>

      {!isLoading && notActivePlaces && !hasActivePlace && (
        <div className={cn(styles.footer, {[styles.footerDesktop]: isDesktop})}>
          <span className={styles.btnWrap}>
            <Button
              ref={setEnablePlusTooltipAnchorEl}
              variant='primary'
              size='l'
              fullWidth
              disabled={isDisabledState || !canManagePlus}
              loading={isFetchLoading}
              onClick={() => selectedPlaces.length > 0 && enablePlus(selectedPlaces, selectedServices)}
              className={styles.button}
              data-testid={'plus-drawer-yellow-button' /* Шторка Плюса | Желтая кнопка Подключить Плюс */}
            >
              <Text align='center' color='inherit'>
                <Typography variant='body2' thickness='medium'>
                  {t('shared-places.plus-drawer.enable-plus', 'Подключить Плюс')}
                </Typography>
              </Text>
            </Button>
            <div className={styles.footerText}>
              <Typography variant='caption1' lineHeight='loose' thickness='regular' color='minor' mt='s' px='xl'>
                {t(
                  'shared-places.plus-drawer.enable-plus-offer',
                  'Нажимая «Подключить Плюс», вы соглашаетесь с условиями подключения'
                )}
              </Typography>
            </div>
          </span>
        </div>
      )}

      {enablePlusTooltipAnchorEl && !canManagePlus && <OperatorInfoTooltip anchorEl={enablePlusTooltipAnchorEl} />}

      <DisablePlusQuiz
        opened={isOpenDisablePlusQuiz}
        onClose={() => setIsOpenDisablePlusQuiz(false)}
        onSubmit={() => setIsOpenDisablePlusQuiz(false)}
      />

      <Dialog
        slug={'plus_confirm_disabled'}
        outsideClose={false}
        progress={false}
        visible={Boolean(plusDisableConfirmationData)}
        title={t('main-places.ya-plus.tochno-otklyuchit-plyus', 'Точно отключить Плюс?')}
        applyText={t('main-places.ya-plus.otklyuchit', 'Отключить')}
        cancelText={t('main-places.ya-plus.ne-otklyuchat', 'Не отключать')}
        onConfirm={() => {
          handleSubmitDisablePlus()
        }}
        onClose={() => {
          setPlusDisableConfirmationData(undefined)
        }}
      >
        {t(
          'shared-places.plus-drawer.users-will-pay-less-attention-to-you',
          'Пользователи Плюса будут реже обращать на вас внимание — заказов может стать меньше'
        )}
      </Dialog>
    </>
  )
}

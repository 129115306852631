import {useExp3} from '@eda-restapp/configs'
import moment from 'moment'
import {useEffect, useState} from 'react'

import {useApiQuery} from '@restapp/core-api'
import {usePlacesApi} from '@restapp/core-places'

import {useIsPromotionAvailable} from './useIsPromotionAvailable'

const URL = '/4.0/restapp-front/marketing/v1/newbie-promo'
const METHOD = 'POST'
const DEFAULT_PROMOCODE = 'newbie'

/**
 * @param promocodeType default 'newbie'
 */
export const usePromocode = (promocodeType: string = DEFAULT_PROMOCODE) => {
  const isPromotionAvailable = useIsPromotionAvailable()
  const {placesIds} = usePlacesApi()
  const promotionConfig = useExp3('restapp_promotion')
  const [expired, setExpired] = useState(false)
  const isNewbiePromocodeEnabled = promotionConfig.isPromocodesEnabled && promocodeType === DEFAULT_PROMOCODE
  const isBalancePromocodeEnabled =
    !!promotionConfig.isBalancePromocodeEnabled &&
    !!promotionConfig.balancePromocodeType &&
    promocodeType === promotionConfig.balancePromocodeType
  const isPromocodesEnabled = isNewbiePromocodeEnabled || isBalancePromocodeEnabled
  const requestEnabled = isPromocodesEnabled && isPromotionAvailable && Boolean(placesIds?.length)

  const {data, isLoading, isFetching, refetch} = useApiQuery({
    url: URL,
    method: METHOD,
    refetchOnMount: false,
    enabled: requestEnabled,
    body: {
      places_ids: placesIds
    },
    onSuccess: (newData) => {
      if (data?.promo?.expired_at !== newData.promo?.expired_at) {
        setExpired(false)
      }
    }
  })

  const expireAt = data?.promo?.expired_at
  const isValidType = !!data?.promo?.promocode_type && data.promo.promocode_type === promocodeType
  const isValid = moment(expireAt).unix() <= Date.now()
  const enabled = !expired && isValidType && requestEnabled

  useEffect(() => {
    if (expireAt && new Date(expireAt).getTime() < Date.now()) {
      setExpired(true)
    }
  }, [expireAt])

  return {
    promocode: enabled && isValid ? data?.promo?.promocode : undefined,
    information: enabled && isValid ? data?.promo?.information : undefined,
    expireAt,
    refetch,
    isLoading: isLoading,
    isFetching: isFetching,
    isEnabled: enabled
  }
}

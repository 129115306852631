import {PlaceTariffBadge, Box} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'

import {PlaceAlertTrialTooltip} from '@restapp/subscription/components/PlaceAlertTrialTooltip'

import {useTariffBadge} from '../../hooks/useTariffBadge'

import styles from './PlaceAlertTrialBadge.module.css'

interface TPlaceAlertTrialBadgeProps {
  placeId: number
  className?: string
}

export const PlaceAlertTrialBadge: React.FC<TPlaceAlertTrialBadgeProps> = ({placeId, className}) => {
  const tariffBadge = useTariffBadge(placeId)

  if (!tariffBadge || !tariffBadge.isTrial) {
    return null
  }

  return (
    <PlaceAlertTrialTooltip
      validUntilIso={tariffBadge.validUntilIso}
      className={className}
      isPaidNextTariff={tariffBadge.nextTariff !== 'free'}
    >
      <Box alignItems='center'>
        <PlaceTariffBadge
          fontSize='24px'
          className={cn({[styles.businessTrialGradient]: tariffBadge.tariffType === 'business_plus'})}
        />
      </Box>
    </PlaceAlertTrialTooltip>
  )
}

/** Перезапустит анимацию только в случае, если воспроизведение закончилась */
export const useRefreshAnimation = (anchorEl: HTMLElement | null) => {
  return () => {
    if (!anchorEl || !anchorEl.getAnimations) {
      return
    }

    if (anchorEl.getAnimations()[0]?.playState === 'running') {
      return
    }

    /**
     * Здесь используется перезапуск анимации.
     * Если мы переопределим анимацию элемента на none и обратно, ничего не произойдёт.
     *
     * anchorEl.offsetHeight заставляет браузер выполнить синхронный пересчёт без перерисовки
     * После пересчёта он запомнит свойство none на элементе,
     * а заданной из стилей анимации после строки anchorEl.offsetHeight для элемента не будет существовать.
     *
     * После того как анимация была переопределена, снятие style.animation запустит анимацию заново,
     * так как для элемента она будет новой. (Пересчёт и запуск произойдёт асинхронно)
     */
    anchorEl.style.animation = 'none'
    anchorEl.offsetHeight
    anchorEl.style.animation = ''
  }
}

import type {ShortRestaurant, VendorApi} from '@restapp/shared-api'

export const SHORT_PLACES_MOCK: ShortRestaurant[] = [
  {
    id: 198,
    name: 'Нана у Нуны',
    is_available: false,
    is_switching_on_requested: true,
    disable_details: {
      disable_at: '2022-11-02T10:14:00+00:00',
      available_at: '2022-11-03T21:00:00+00:00',
      status: 0,
      reason: 14
    },
    address: 'улица Петровка, 20/1, Москва, Российская Федерация',
    type: 'native',
    integration_type: 'native',
    can_be_enabled: false,
    services: {
      available: [
        {
          service: 'dc',
          name: 'Delivery Club'
        },
        {
          service: 'eda',
          name: 'Яндекс Еда'
        }
      ],
      enabled: ['dc', 'eda']
    }
  },
  {
    id: 331,
    name: 'il Forno',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Неглинная улица, 10, Москва, Беларусь',
    type: 'native',
    integration_type: 'native',
    services: {
      available: [
        {
          service: 'eda',
          name: 'Яндекс Еда'
        }
      ],
      enabled: ['eda']
    }
  },
  {
    id: 46874,
    name: 'Хмель и солод',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Абрамцевская улица, 9к1, Москва, Российская Федерация',
    type: 'native',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 129840,
    name: 'Домашняя кулинария',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Холмогорская улица, 4В, Пермь, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda', 'dc'], available: []}
  },
  {
    id: 356767,
    name: 'Place Native  gtbdrm122038',
    is_available: true,
    is_switching_on_requested: false,
    address: 'улица Алроса, 2Д, Орёл, Российская Федерация',
    type: 'native',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 48116,
    name: 'The Mad Cock',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Пресненская набережная, 12, Москва, Российская Федерация',
    type: 'native',
    integration_type: 'native',
    services: {enabled: ['eda', 'dc'], available: []}
  },
  {
    id: 10524,
    name: 'Пииндус',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Чистопрудный бульвар, 12к4, Москва, Российская Федерация',
    type: 'native',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11172,
    name: 'Пицца Плюс',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 1, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda', 'dc'], available: []}
  },
  {
    id: 11173,
    name: 'Пицца Минус',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 2, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11174,
    name: 'Пицца Плюс-Минус',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 3, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11175,
    name: 'Пицца Больше',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 4, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11176,
    name: 'Пицца Меньше',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 5, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11177,
    name: 'Пицца Вкуснее',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 5, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11178,
    name: 'Пицца Острее',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 5, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11179,
    name: 'Пицца Слаще',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 5, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11180,
    name: 'Пицца Гуще',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 5, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 11181,
    name: 'Пицца в ананасе',
    is_available: false,
    is_switching_on_requested: false,
    disable_details: {
      disable_at: '2021-11-08T11:53:59+00:00',
      available_at: '2021-11-08T12:23:58+00:00',
      status: 0,
      reason: 28
    },
    address: 'Театральная площадь, 5, Рязань, Российская Федерация',
    type: 'marketplace',
    integration_type: 'native',
    services: {enabled: ['eda'], available: []}
  },
  {
    id: 357220,
    name: 'Плейсыч',
    is_available: true,
    is_switching_on_requested: false,
    address: 'Тверская улица, 7, Москва, Российская Федерация',
    type: 'native',
    integration_type: 'native',
    services: {
      available: [
        {
          service: 'eda',
          name: 'Яндекс Еда'
        }
      ],
      enabled: ['eda']
    }
  }
]

export const PLACE_SUGGEST_RESPONSE_MOCK: VendorApi['/4.0/restapp-front/places/v1/suggest']['GET']['response'] = {
  meta: {count: SHORT_PLACES_MOCK.length},
  payload: SHORT_PLACES_MOCK
}

import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'

export const useTariffBadges = () => {
  const {t} = useI18n()
  const {
    subscriptionPage: {tariffBadges}
  } = useExp3('restapp_tools')

  const tariffNames = {
    free: t('shared-places.tariff-badge.name-free', 'Без подписки'),
    business: t('shared-places.tariff-badge.name-business', 'Стандарт'),
    business_plus: t('shared-places.tariff-badge.name-business-plus', 'Бизнес')
  }

  const getTariffBadgesByType = (
    tariffType?: string
  ): {name: string; background?: string; color?: string; type?: string; arrowsColor?: string} | undefined => {
    if (!tariffType || !Object.keys(tariffNames).includes(tariffType)) {
      return
    }

    const currentBadge = tariffBadges.find((tariffBadge) => tariffBadge.type === tariffType)
    const name = tariffNames[tariffType as keyof typeof tariffNames]

    if (currentBadge) {
      return {
        ...currentBadge,
        name
      }
    }
  }

  return {
    getTariffBadgesByType
  }
}

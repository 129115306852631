import {useExp3} from '@eda-restapp/configs'
import {useI18n} from '@eda-restapp/i18n'
import {RUM} from '@eda-restapp/logger'
import {Messenger} from '@eda-restapp/microfrontend'
import {Box, ChevronLeftMIcon, useIsDesktopStrict, useIsMobileStrict} from '@eda-restapp/ui'
import type {DrawerProps} from '@mui/material'
import {Drawer} from '@mui/material'
import cn from 'classnames'
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {useServiceBrand} from '@restapp/shared'
import {LanguageSelector} from '@restapp/shared/components'
import {SnowfallContainer} from '@restapp/shared/components/Snowfall/Snowfall.container'

import {NewYearLogo} from './NewYearLogo/NewYearLogo'
import {useLoadGarlandImages} from './NewYearLogo/useLoadGarlandImages'
import {useRefreshAnimation} from './NewYearLogo/useRefreshAnimation'
import {useSidebarStyles} from './Sidebar.styles'
import type {SidebarElement, SidebarIFrame, SidebarLink} from './Sidebar.types'
import SidebarNavElement from './SidebarNavElement'
import {SidebarNavGroup} from './SidebarNavGroup'

export type SidebarProps = {
  logoPath: string
  isVisible: boolean
  setVisibility: (isVisible: boolean) => void
  webVersion: string
  appVersion?: string
  mainElements: SidebarElement[]
  footerElements: SidebarElement[]
  Widget?: React.ReactNode
  variant?: DrawerProps['variant']
  renderAccount: (mini: boolean) => React.ReactNode
  onNavigationPerformed: () => void
}

const Sidebar: React.FC<SidebarProps> = ({
  logoPath,
  setVisibility,
  isVisible,
  webVersion,
  mainElements,
  footerElements,
  Widget,
  variant,
  appVersion,
  renderAccount,
  onNavigationPerformed
}) => {
  const {t} = useI18n()
  const serviceBrand = useServiceBrand()
  const {classes: c} = useSidebarStyles()
  const isDesktop = useIsDesktopStrict()
  const isMobile = useIsMobileStrict()
  const toggleSidebar = () => setVisibility(!isVisible)
  const location = useLocation()

  const newYearConfig = useExp3('restapp_new_year')
  /** garlandEl будет существовать только, если включен new_year */
  const [garlandEl, setGarlandEl] = useState<HTMLElement | null>(null)
  const [garlandMozEl, setGarlandMozEl] = useState<HTMLElement | null>(null)
  /** Отработает только, если garlandEl not null */
  const refreshGarlandAnimation = useRefreshAnimation(garlandEl)
  const refreshGarlandMozAnimation = useRefreshAnimation(garlandMozEl)
  /** Загружает картинки и выставляет как переменные */
  useLoadGarlandImages(garlandEl, garlandMozEl, serviceBrand)

  const promotionOnboardingLandingConfig = useExp3('restapp_promotion_onboarding_landing')

  const [sidebarAnchorEl, setSidebarAnchorEl] = useState<HTMLElement | null>(null)

  Messenger.useReceiveEvent(Messenger.MessageType.OPEN_SIDEBAR, toggleSidebar)

  const isMiniDrawer = !isVisible && isDesktop
  const isSquareDrawer = promotionOnboardingLandingConfig.enabled && location.pathname === '/promotion'

  const renderElement = (sidebarElement: SidebarElement) => {
    // TODO: Причесать после выпила Sidebar.legacy
    if (sidebarElement.type === 'separator') {
      return (
        <div key={sidebarElement.label} className={cn(c.separator, {[c.separatorMini]: isMiniDrawer})}>
          {sidebarElement.label}
        </div>
      )
    }

    const onLinkClick = (link: SidebarLink | SidebarIFrame) => {
      if (link.onClick) {
        link.onClick()
      }
      initSidebarClickRumScenarios(link)
      onNavigationPerformed()
      refreshGarlandAnimation()
      refreshGarlandMozAnimation()
    }

    if (sidebarElement.type === 'group') {
      return (
        <SidebarNavGroup
          key={sidebarElement.label}
          mini={isMiniDrawer}
          navGroup={sidebarElement}
          onLinkClick={onLinkClick}
        />
      )
    }

    if (!sidebarElement.visible) {
      return null
    }

    if (sidebarElement.path === '/logout') {
      // TODO: Remove, when logout will be deleted from configs.
      return null
    }

    return (
      <SidebarNavElement
        mini={isMiniDrawer}
        key={sidebarElement.path}
        path={sidebarElement.path}
        label={sidebarElement.name}
        color={sidebarElement.color}
        onClick={() => onLinkClick(sidebarElement)}
        icon={sidebarElement.icon}
        badge={sidebarElement.badge}
        external={sidebarElement.external}
        target={sidebarElement.target}
      />
    )
  }

  const getVersions = () => {
    const text = isMiniDrawer ? '' : `${t('main-layout.sidebar.versiya', 'Версия:')} `
    const VERSION_REGEXP = /(\d+\.)?(\d+\.)?(\*|\d+)?(hotfix)?(\d+)?/
    const arrayMatchWebVersionWithRegexp = webVersion.match(VERSION_REGEXP)
    const matchWebVersion =
      arrayMatchWebVersionWithRegexp && arrayMatchWebVersionWithRegexp[0] ? arrayMatchWebVersionWithRegexp[0] : 'DEV'

    const versions = [appVersion, matchWebVersion].filter((v) => v)

    return text + versions.join(isMiniDrawer ? ' ' : ' / ')
  }

  useEffect(() => {
    RUM.sendScenariosDelta('core_init', 'sidebar_links_full_load')
  }, [])

  // Можем не ренедрить на мобилках свернутый сайдбар
  if (!isDesktop && !isMiniDrawer && !isVisible) {
    return null
  }

  return (
    <Drawer
      classes={{
        root: cn(isDesktop && newYearConfig.snowfall?.enabled && c.relativeRoot),
        paper: cn(c.drawerPaper, c.styledScrollbar, {
          [c.drawerPaperSquare]: isSquareDrawer,
          [c.drawerPaperMini]: isMiniDrawer,
          [c.miniSidebarScroll]: isMiniDrawer,
          [c.drawerPaperHidden]: !isVisible && !isDesktop,
          [c.newYearDrawerPaper]: newYearConfig.enabled
        })
      }}
      ref={setSidebarAnchorEl}
      data-isminidrawer={isMiniDrawer} // Используется в стилях бейджей для правильного отображения в свернутом сайдбаре
      variant={variant || (!isDesktop ? 'temporary' : 'permanent')}
      anchor='left'
      open={isVisible}
      ModalProps={{keepMounted: !isDesktop}}
      data-testid={'ui-sidebar-drawer' /* UI | Шторка сайдбара (навигация) */}
      elevation={16}
      onClose={() => setVisibility(false)}
    >
      <Box className={c.wrapper} flex={1} flexDirection='column'>
        <Box flex={1} flexDirection='column'>
          <div
            className={cn(c.head, {
              [c.newYearHead]: newYearConfig.enabled,
              [c.headWhenOpen]: isVisible
            })}
          >
            {!newYearConfig.enabled && (
              <>
                {isVisible && (
                  <div className={c.logo} style={{backgroundImage: `url(${logoPath})`}} data-testid='sidebar-label' />
                )}
                <ChevronLeftMIcon
                  data-testid='sidebar-toggle-icon'
                  className={cn(c.toggleChevron, {
                    [c.rotate180]: !isVisible
                  })}
                  onClick={toggleSidebar}
                />
              </>
            )}
            {newYearConfig.enabled && (
              <>
                <NewYearLogo setLogoRef={setGarlandEl} setLogoMozRef={setGarlandMozEl} isSidebarVisible={isVisible} />
                <Box
                  className={c.newYearChevronContainer}
                  style={{
                    right: (isMobile && 16) || isVisible ? 8 : 20
                  }}
                >
                  <ChevronLeftMIcon
                    data-testid='sidebar-toggle-icon'
                    className={cn(c.toggleChevron, c.newYearToggleChevron, {
                      [c.rotate180]: !isVisible
                    })}
                    onClick={toggleSidebar}
                  />
                </Box>
              </>
            )}
          </div>
          {!isMiniDrawer && Widget}
          <Box className={c.navList} flex={1} flexDirection='column' mt='s'>
            {mainElements.map((link) => renderElement(link))}
          </Box>
          <Box flexDirection='column' mt='l'>
            {footerElements.map((link) => renderElement(link))}
            {renderAccount(isMiniDrawer)}
          </Box>
          <Box
            flexDirection={isMiniDrawer ? 'column' : 'row'}
            justifyContent='space-between'
            pl={isMiniDrawer ? undefined : 'm'}
          >
            <div data-testid='sidebar-version' className={cn(c.versionBlock, {[c.versionBlockMini]: isMiniDrawer})}>
              {getVersions()}
            </div>
            <LanguageSelector
              classes={{
                button: cn({
                  [c.laguageSelectorHoverButton]: isMiniDrawer,
                  [c.newYearLanguageSelectorButton]: newYearConfig.enabled
                }),
                menu: c.languageSelectorMenu
              }}
              anchorOrigin={{
                vertical: isDesktop ? 'bottom' : 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: isDesktop ? 'left' : 'right'
              }}
            />
          </Box>
        </Box>
      </Box>
      {newYearConfig?.snowfall?.enabled && (
        <SnowfallContainer isVisible={isVisible || isDesktop} anchorEl={sidebarAnchorEl} />
      )}
    </Drawer>
  )
}

function initSidebarClickRumScenarios(link: SidebarLink | SidebarIFrame) {
  switch (link.path) {
    case '/menu': {
      RUM.setScenariosTimeStamp('sidebar_menu_click')
      break
    }
    case '/places': {
      RUM.setScenariosTimeStamp('sidebar_places_click')
      break
    }
    case '/tools': {
      RUM.setScenariosTimeStamp('sidebar_tools_click')
      break
    }
    default: {
      return
    }
  }
}

export default Sidebar

import {useExp3} from '@eda-restapp/configs'
import {useOnboardingController} from '@eda-restapp/hooks'
import {useI18n} from '@eda-restapp/i18n'
import {metrika} from '@eda-restapp/logger'
import {ChevronRightMIcon, StarOfBethlehemViolet, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import moment from 'moment'
import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {usePlaces} from '@restapp/core-places'
import type {TariffType} from '@restapp/shared-api'
import {SubscriptionModals} from '@restapp/subscription/components/SubscriptionModalsContainer/SubscriptionModals'

import {useSubscriptionRoute} from '../../hooks/useSubscriptionRoute'
import {useSubscriptions} from '../../hooks/useSubscriptions'
import {useTelegramList} from '../../hooks/useTelegramList'
import {SubscriptionButtonTooltip} from '../SubscriptionButtonTooltip'

import {SubscriptionButtonIcon} from './SubscriptionButton.icon'
import {useSubscriptionButtonStyles} from './SubscriptionButton.styles'

interface IProps {
  className?: string
  hideTooltip?: boolean
}

const getOnboardingTooltipSlug = (hasPaidSubscription: boolean, subscribitionType?: TariffType) => {
  if (subscribitionType !== undefined) {
    return 'shared-places:subscription-button:promo_' + subscribitionType
  }

  if (hasPaidSubscription) {
    return 'shared-places:subscription-button:has_paid_subscription_without_type'
  }

  return 'shared-places:subscription-button:has_no_subscription'
}

export const SubscriptionButton: React.FC<IProps> = ({className, hideTooltip}) => {
  const {t} = useI18n()

  const {classes: c} = useSubscriptionButtonStyles()
  const isMobile = useIsMobileStrict()
  const location = useLocation()
  const [isOpenSubscriptionModals, setIsOpenSubscriptionModals] = useState(false)

  const placesQuery = usePlaces()
  const navigate = useNavigate()

  const {goToSubcriptionStart, goToTGAccounts, goToSubcription, openTariffModal} = useSubscriptionRoute()

  const toolsConfig = useExp3('restapp_tools')
  const commonConfig = useExp3('restapp_common')
  const enabledSubcriptionPage =
    toolsConfig.enabled && toolsConfig.enabledSubscription && toolsConfig.subscriptionPage?.enabled

  const subscriptionsQuery = useSubscriptions(
    {placesIds: placesQuery.placesIds},
    {enabled: !!placesQuery.placesIds.length}
  )
  const hasPromoTrial = subscriptionsQuery.data?.subscriptions.some(
    (item) => item.tariff_info.type === toolsConfig.promoSubscriptionType && item.is_trial
  )
  const placesIdsHasAnySubscription = subscriptionsQuery.data?.subscriptions.map(({place_id}) => place_id)
  const placesIdsFreeSubscription = subscriptionsQuery.data?.subscriptions
    .filter((subscription) => subscription.tariff_info.type && subscription.tariff_info.type === 'free')
    .map(({place_id}) => place_id)
  const telegramListQuery = useTelegramList(placesIdsHasAnySubscription || [])

  const isPromoRange =
    moment().isSameOrAfter(moment(toolsConfig.dateSinceShowPromoButton)) &&
    moment().isBefore(moment(toolsConfig.dateEndShowPromoButton))

  const telegramList = telegramListQuery.filledData?.filter((data) => data.logins.length)
  const hasPaidSubscription = subscriptionsQuery.data?.subscriptions.some(
    (subscription) => subscription.tariff_info.type && subscription.tariff_info.type !== 'free'
  )

  const onboardingTooltipSlug = getOnboardingTooltipSlug(
    Boolean(subscriptionsQuery.data) && Boolean(hasPaidSubscription),
    isPromoRange ? toolsConfig.promoSubscriptionType : undefined
  )

  const onboardingController = useOnboardingController(
    onboardingTooltipSlug,
    commonConfig.queuedTooltips,
    !!onboardingTooltipSlug
  )

  const onSubscribeButtonClick = () => {
    metrika({
      target: 'header_button_subscribe_button_click',
      params: {
        placesCountWithFreeSubscription: placesIdsFreeSubscription?.length,
        hasPaidSubscription,
        currentPath: location.pathname
      }
    })

    onboardingController.close()
    goToSubcriptionStart()
  }

  const onPromoSubscribeButtonClick = () => {
    metrika({
      target: 'header_button_promo_subscribe_button_click',
      params: {
        placesCountWithFreeSubscription: placesIdsFreeSubscription?.length,
        hasPaidSubscription,
        currentPath: location.pathname
      }
    })

    onboardingController.close()

    if (toolsConfig.infoWidgetTrialLink?.pathname) {
      navigate({
        pathname: toolsConfig.infoWidgetTrialLink.pathname,
        search: toolsConfig.infoWidgetTrialLink.search
      })

      return
    }

    if (placesIdsFreeSubscription) {
      if (toolsConfig.enableSubscriptionMultiselectChoice) {
        setIsOpenSubscriptionModals(true)
      } else {
        openTariffModal(placesIdsFreeSubscription)
      }

      return
    }

    goToSubcription()
  }

  const onSettingsButtonClick = () => {
    metrika({
      target: 'header_button_settings_subscribe_button_click',
      params: {
        placesCountWithFreeSubscription: placesIdsFreeSubscription?.length,
        hasPaidSubscription,
        currentPath: location.pathname
      }
    })

    onboardingController.close()

    goToSubcription()
  }

  const onSubscribeTooltipButtonClick = () => {
    metrika({
      target: 'header_tooltip_subscribe_button_click',
      params: {
        placesCountWithFreeSubscription: placesIdsFreeSubscription?.length,
        hasPaidSubscription,
        currentPath: location.pathname
      }
    })

    onboardingController.close()
    goToSubcriptionStart()
  }

  const handleCloseSubscriptionModals = () => {
    setIsOpenSubscriptionModals(false)
  }

  const onMessengerSettingsTooltipButtonClick = () => {
    metrika({
      target: 'header_tooltip_messenger_settings_subscribe_button_click',
      params: {
        placesCountWithFreeSubscription: placesIdsFreeSubscription?.length,
        hasPaidSubscription,
        currentPath: location.pathname
      }
    })

    onboardingController.close()
    goToTGAccounts()
  }

  if (
    !enabledSubcriptionPage ||
    !subscriptionsQuery.data?.subscriptions.length ||
    telegramListQuery.isLoading ||
    subscriptionsQuery.isLoading
  ) {
    return null
  }

  const isBusiness = toolsConfig.promoSubscriptionType === 'business_plus'
  if (isPromoRange && hasPromoTrial) {
    return (
      <>
        <SubscriptionButtonTooltip
          isOnboardingTooltipOpen={onboardingController.isOpen}
          closeOnboardingTooltip={onboardingController.close}
          telegramList={telegramList}
          hasPaidSubscription
          showPromoByType={toolsConfig.promoSubscriptionType}
          openMessengerSettings={onMessengerSettingsTooltipButtonClick}
          openSubscription={onSubscribeTooltipButtonClick}
          hideTooltip={hideTooltip}
        >
          <div
            className={cn(c.root, c.promoSubscription, {[c.businessPromoSubscriptionButton]: isBusiness}, className)}
            onClick={onPromoSubscribeButtonClick}
          >
            <div className={cn(c.icon, {[c.businessIcon]: isBusiness})}></div>
            <div className={cn(c.text, {[c.businessPromoText]: isBusiness})}>
              {t('shared-places.subscription-button.subscription-promo', 'Подписка в подарок')}
            </div>

            {isMobile && (
              <ChevronRightMIcon className={c.iconArrow} color={isBusiness ? 'white' : undefined} fontSize='32' />
            )}
          </div>
        </SubscriptionButtonTooltip>

        {toolsConfig.enableSubscriptionMultiselectChoice && (
          <SubscriptionModals
            isOpen={isOpenSubscriptionModals}
            placesIds={placesIdsFreeSubscription ?? []}
            onClose={handleCloseSubscriptionModals}
            isFeatureSubscription
          />
        )}
      </>
    )
  }

  if (subscriptionsQuery.data && hasPaidSubscription) {
    return (
      <SubscriptionButtonTooltip
        isOnboardingTooltipOpen={onboardingController.isOpen}
        closeOnboardingTooltip={onboardingController.close}
        telegramList={telegramList}
        hasPaidSubscription
        openMessengerSettings={onMessengerSettingsTooltipButtonClick}
        openSubscription={onSubscribeTooltipButtonClick}
        hideTooltip={hideTooltip}
      >
        <div
          className={cn(c.root, c.withSubscription, className)}
          data-testid={'subscription-settings-button' /* Инструменты | Кнопка Управлять подпиской */}
          onClick={onSettingsButtonClick}
        >
          <StarOfBethlehemViolet fontSize='20' className={c.icon} />
          <div className={c.text}>
            {t('shared-places.subscription-button.subscription-settings', 'Управлять подпиской')}
          </div>
          {isMobile && <ChevronRightMIcon className={c.iconArrow} fontSize='32' />}
        </div>
      </SubscriptionButtonTooltip>
    )
  }

  if (subscriptionsQuery.data && subscriptionsQuery.data.subscriptions.length) {
    return (
      <SubscriptionButtonTooltip
        isOnboardingTooltipOpen={onboardingController.isOpen}
        closeOnboardingTooltip={onboardingController.close}
        hasPaidSubscription
        openMessengerSettings={onMessengerSettingsTooltipButtonClick}
        openSubscription={onSubscribeTooltipButtonClick}
        hideTooltip={hideTooltip}
      >
        <div
          data-testid={'subscription-connect-button' /* Инструменты | Кнопка Подключить подписку */}
          className={cn(c.root, c.withoutSubscribtion, className)}
          onClick={onSubscribeButtonClick}
        >
          <SubscriptionButtonIcon className={c.icon} />
          <div className={c.text}>
            {t('shared-places.subscription-button.connect-subscription', 'Подключить подписку')}
          </div>
          {isMobile && <ChevronRightMIcon className={c.iconArrow} fontSize='32' />}
        </div>
      </SubscriptionButtonTooltip>
    )
  }

  return null
}

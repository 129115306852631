import {getMapsUrl} from '@eda-restapp/static-url'

import {loadScript} from '../loadScript'

export type YMaps = typeof window.ymaps

let loadPromise: Promise<YMaps> | null = null

export function loadYMaps(
  lang: string = 'ru',
  countryCode: string = 'RU',
  enabledMapsUrl: boolean = false
): Promise<YMaps> {
  if (loadPromise !== null) {
    return loadPromise
  }

  const mapLang = lang === 'ka' ? 'en_US' : `${lang}_${countryCode}`
  const mapsUrl = getMapsUrl({lang: mapLang, apiKey: 'c0d403ab-e5be-4049-908c-8122a58acf23', enabled: enabledMapsUrl})

  loadPromise = new Promise((resolve) => {
    loadScript(mapsUrl)
      .then(() => {
        // tslint:disable-next-line
        void window.ymaps.ready(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore https://st.yandex-team.ru/EDADUTY-2840#616ec9a3723c5031444ddb25
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.ymaps.modules.__modules._sandbox.env.hosts.api.services.suggest +=
            'suggest-geo?client_id=eats_vendorka&_='
          resolve(window.ymaps)
        })
      })
      .catch(() => null)
  })

  return loadPromise
}

import {createAction} from '@reduxjs/toolkit'

import type {Category as CategoryType, MenuItem as MenuItemType} from '@restapp/shared-api'

import {MENU_SLICE_NAME} from './constants'
import type {OptionVirtualId} from './types'

/** Нормализует все переданные блюда и заменяет ими текущий стейт */
export const setNormalizeItems = createAction(`${MENU_SLICE_NAME}/setNormalizeItems`, (items: MenuItemType[]) => {
  return {
    payload: items
  }
})

/** Нормализует все переданные категории и заменяет ими текущий стейт */
export const setNormalizeCategories = createAction(
  `${MENU_SLICE_NAME}/setNormalizeCategories`,
  (items: CategoryType[]) => {
    return {
      payload: items
    }
  }
)

export const setMenuRevision = createAction<string>(`${MENU_SLICE_NAME}/setRevision`)

/** @deprecated */
export const createItem = createAction(`${MENU_SLICE_NAME}/createItem`, (item: MenuItemType) => {
  return {
    payload: item
  }
})

/** @deprecated */
export const upsertItem = createAction(`${MENU_SLICE_NAME}/upsertItem`, (item: MenuItemType) => {
  return {
    payload: item
  }
})

export const removeItem = createAction(`${MENU_SLICE_NAME}/removeItem`, (id: string) => {
  return {
    payload: id
  }
})

export const resetAll = createAction(`${MENU_SLICE_NAME}/resetAll`)

/** Применить измененное состояние меню. Противоположный action resetAll */
export const applyCurrentState = createAction(`${MENU_SLICE_NAME}/applyCurrentState`)

export const toggleOption = createAction(
  `${MENU_SLICE_NAME}/toggleOption`,
  (payload: {id: OptionVirtualId; value: boolean; reactivatedAd: string | null}) => {
    return {
      payload: payload
    }
  }
)

export const toggleManyOption = createAction(
  `${MENU_SLICE_NAME}/toggleManyOption`,
  (payload: {id: OptionVirtualId; value: boolean; reactivatedAd?: string | null}[]) => {
    return {
      payload: payload
    }
  }
)

export const enableAll = createAction(`${MENU_SLICE_NAME}/enableAll`)

export const toggleCategory = createAction(
  `${MENU_SLICE_NAME}/toggleCategory`,
  (payload: {id: string; value: boolean; reactivatedAt?: string | null}) => {
    return {
      payload: {
        ...payload,
        reactivatedAt: payload.reactivatedAt ?? null
      }
    }
  }
)

export const toggleItem = createAction(
  `${MENU_SLICE_NAME}/toggleItem`,
  (payload: {id: string; value: boolean; reactivatedAt?: string | null}) => {
    return {
      payload: {
        ...payload,
        reactivatedAt: payload.reactivatedAt ?? null
      }
    }
  }
)

/** Удаляет категорию и все зависимые сущности (блюда, группы, опции) */
export const removeCategory = createAction(`${MENU_SLICE_NAME}/removeCategory`, (payload: string) => {
  return {
    payload: payload
  }
})

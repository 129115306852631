import moment from 'moment'

import type {PlusStatusByService, Service} from '@restapp/shared-api'
import {YaPlusStatusEnum} from '@restapp/shared-api'

type PlusStatusTextSlug =
  | 'DC_ACTIVATING'
  | 'EDA_ACTIVE'
  | 'BOTH_SERVICES_ACTIVE'
  | 'EDA_ACTIVE_DC_ACTIVATING'
  | 'EDA_ACTIVE_DC_DISABLED'
  | 'EDA_DISABLED_DC_ACTIVATING'
  | 'EDA_DISABLED_DC_ACTIVE'
  | 'DEFAULT'
  | 'EDA_DISABLED'
  | 'DC_DISABLED'
  | 'DC_ACTIVE'

export function getPlusStatusTextSlug(placeInfoByService: PlusStatusByService[] | undefined): PlusStatusTextSlug {
  if (placeInfoByService?.length === 2) {
    const edaStatuses = placeInfoByService?.find((plusInfo) => plusInfo.service === 'eda')
    const edaStatus = edaStatuses && edaStatuses.status_info

    const dcStatuses = placeInfoByService?.find((plusInfo) => plusInfo.service === 'dc')
    const dcStatus = dcStatuses && dcStatuses.status_info

    if (dcStatus && edaStatus) {
      if (
        edaStatus.status === YaPlusStatusEnum.ACTIVE ||
        edaStatus.status === YaPlusStatusEnum.ACTIVATING ||
        edaStatus.status === YaPlusStatusEnum.WAIT_TO_START ||
        edaStatus.status === YaPlusStatusEnum.DEACTIVATING
      ) {
        if (dcStatus.status === YaPlusStatusEnum.ACTIVE || dcStatus.status === YaPlusStatusEnum.DEACTIVATING) {
          return 'BOTH_SERVICES_ACTIVE'
        }

        if (dcStatus.status === YaPlusStatusEnum.ACTIVATING || dcStatus.status === YaPlusStatusEnum.WAIT_TO_START) {
          return 'EDA_ACTIVE_DC_ACTIVATING'
        }

        return 'EDA_ACTIVE_DC_DISABLED'
      } else {
        if (dcStatus.status === YaPlusStatusEnum.ACTIVE || dcStatus.status === YaPlusStatusEnum.DEACTIVATING) {
          return 'EDA_DISABLED_DC_ACTIVE'
        }

        if (dcStatus.status === YaPlusStatusEnum.ACTIVATING || dcStatus.status === YaPlusStatusEnum.WAIT_TO_START) {
          return 'EDA_DISABLED_DC_ACTIVATING'
        }
      }
    }
  } else {
    const plusStatus = placeInfoByService?.[0]

    if (plusStatus) {
      const status = plusStatus.status_info.status

      if (plusStatus.service === 'eda') {
        if (
          [
            YaPlusStatusEnum.ACTIVE,
            YaPlusStatusEnum.DEACTIVATING,
            YaPlusStatusEnum.ACTIVATING,
            YaPlusStatusEnum.WAIT_TO_START
          ].includes(status)
        ) {
          return 'EDA_ACTIVE'
        }

        return 'EDA_DISABLED'
      }

      if (plusStatus.service === 'dc') {
        if (status === YaPlusStatusEnum.ACTIVE || status === YaPlusStatusEnum.DEACTIVATING) {
          return 'DC_ACTIVE'
        }

        if ([YaPlusStatusEnum.ACTIVATING, YaPlusStatusEnum.WAIT_TO_START].includes(status)) {
          return 'DC_ACTIVATING'
        }

        return 'DC_DISABLED'
      }
    }
  }

  return 'DEFAULT'
}

export const getPlusStartDateByService = (service: Service, placeInfoByService: PlusStatusByService[] | undefined) => {
  const dateStart = placeInfoByService?.find((item) => item.service === service)?.status_info?.starts
  return dateStart ? moment(dateStart) : moment().add(1, 'd')
}

import {useI18n} from '@eda-restapp/i18n'
import {eventLogger} from '@eda-restapp/logger'
import type {OutlinedInputClassKey} from '@mui/material/OutlinedInput'
import {DateTimePicker} from '@mui/x-date-pickers'
import cn from 'classnames'
import moment from 'moment'
import React, {type FC, useState, useRef} from 'react'

import {MINUTES_DAY_START, MINUTES_DAY_END} from '@restapp/core-legacy/constants/date'
import useSecondEffect from '@restapp/core-legacy/hooks/useSecondEffect'
import {
  createMomentFromMinutesAfterMidnight,
  convertMomentTimeToMinutes,
  getMinutesAfterMidnight,
  moveCursorToPosition
} from '@restapp/shared/utils'

import useTimeRangeStyles from './TimeRange.styles'

type TextPreset = {
  PLACEHOLDER_TITLE?: string
  CHECKBOX_ALL_DAY_TEXT?: string
  CHECKBOX_DISABLED_ALL_DAY_TEXT?: string
  DATE_FORMAT_ERROR_MSG?: string
  DATE_FORMATS_ERROR_MSG?: string
}

/**
 * Компонент #TimeRange
 * формирует селектор выбора периода времени hh:mm -> hh:mm
 *
 * API:
 * @param from значение в минутах "от"
 * @param to значение в минутах "до"
 * @param disabled флаг доступности/изменения значения
 * @param texts объект для переопределения текстов компонента
 * @param onFromChange событие вызываемое при изменении #from
 * @param onToChange событие вызываемое при изменении #to
 * @param setAllDay устанавливает значение "весь день" #to
 */

type TimeRangeProps = {
  className?: string
  classes?: Partial<Record<keyof typeof useTimeRangeStyles, string>>
} & {
  allDay?: boolean
  disabled?: boolean
  disabledAllDay?: boolean
  from?: number
  slug?: string
  texts?: TextPreset
  to?: number
  classesInput?: Partial<Record<OutlinedInputClassKey, string>>
  onFromChange(val?: number): void
  onToChange(val?: number): void
  setAllDay(status: boolean): void
}

const INPUT_INITIAL_CURSOR_POSITION = 0

export const TimeRange: FC<TimeRangeProps> = ({
  allDay,
  classes,
  classesInput,
  disabled,
  disabledAllDay,
  from,
  slug,
  texts,
  to,
  onFromChange,
  onToChange,
  setAllDay
}) => {
  const {t} = useI18n()
  const {classes: c} = useTimeRangeStyles(undefined, {props: {classes}})

  const DEFAULT_TEXTS = {
    PLACEHOLDER_TITLE: t('shared-ui.time-range.vremya-raboti', 'Время работы'),
    CHECKBOX_ALL_DAY_TEXT: t('shared-ui.time-range.rabotaem-kruglosutochno', 'Работаем круглосуточно'),
    CHECKBOX_DISABLED_ALL_DAY_TEXT: t('shared-ui.time-range.ne-rabotaem-ves-den', 'Не работаем весь день'),
    DATE_FORMAT_ERROR_MSG: t('shared-ui.time-range.nekorrektnii-format-dati', 'Некорректный формат даты'),
    DATE_FORMATS_ERROR_MSG: t('shared-ui.time-range.nekorrektnie-formati-dat', 'Некорректные форматы дат')
  }

  const disabledInput = allDay || disabledAllDay

  const onFromChangeWithTracker = (from: number | undefined) => {
    eventLogger({name: 'time_range_change', value: slug, additional: {from}})
    onFromChange(from)
    updatePropsFrom()
  }

  const onToChangeWithTracker = (to: number | undefined) => {
    eventLogger({name: 'time_range_change', value: slug, additional: {to}})
    onToChange(to)
    updatePropsTo()
  }

  const componentTexts = texts ? {...DEFAULT_TEXTS, ...texts} : DEFAULT_TEXTS

  const normalizedFrom = createMomentFromMinutesAfterMidnight(from)
  const normalizedTo = createMomentFromMinutesAfterMidnight(to)

  const fromInputRef = useRef(null)
  const toInputRef = useRef(null)
  const [fromHasError, setFromError] = useState(false)
  const [toHasError, setToError] = useState(false)

  const handleFromChange = (date: moment.Moment | null) => {
    if (!date || !date.isValid()) {
      onFromChangeWithTracker(MINUTES_DAY_START)
      return
    }

    onFromChangeWithTracker(getMinutesAfterMidnight(date))
  }

  const handleToChange = (date: moment.Moment | null) => {
    if (convertMomentTimeToMinutes(date) === MINUTES_DAY_END && from === 0) {
      // Устанавливаем время окончания акции в 00:00 для избежания ошибки времени - при утсановке времени, отличающегося в 1 цифру от 24:00, удалении этой цифры и установки 24:00
      // инпут не успевает обновить lable (блокируется раньше пересчета), а также стейт (там остается невалидное время после удаления символа - 20:0_)

      onToChangeWithTracker(MINUTES_DAY_START)
      setAllDay(true)
      return
    }

    if (!date || !date.isValid()) {
      onToChangeWithTracker(MINUTES_DAY_START)
      return
    }

    onToChangeWithTracker(getMinutesAfterMidnight(date))
  }

  const updatePropsFrom = () => {
    if (!disabledInput && normalizedFrom.isValid() && getMinutesAfterMidnight(normalizedFrom) === MINUTES_DAY_START) {
      setTimeout(() => moveCursorToPosition(fromInputRef, INPUT_INITIAL_CURSOR_POSITION), 0)
    }
  }

  const updatePropsTo = () => {
    if (!disabledInput && normalizedTo.isValid() && getMinutesAfterMidnight(normalizedTo) === MINUTES_DAY_START) {
      setTimeout(() => moveCursorToPosition(toInputRef, INPUT_INITIAL_CURSOR_POSITION), 0)
    }
  }

  const refreshTimeOnAllDayIfError = () => {
    if (!fromHasError && !toHasError) {
      return
    }

    handleFromChange(normalizedFrom)
    handleToChange(normalizedTo)
  }

  useSecondEffect(refreshTimeOnAllDayIfError, [allDay])

  const renderTitle = () => {
    if (allDay) {
      return componentTexts.CHECKBOX_ALL_DAY_TEXT
    }
    if (disabledAllDay) {
      return componentTexts.CHECKBOX_DISABLED_ALL_DAY_TEXT
    }

    return !from && !to
      ? componentTexts.PLACEHOLDER_TITLE
      : `${moment(normalizedFrom).format('HH:mm')} — ${moment(normalizedTo).format('HH:mm')}`
  }

  const renderError = () =>
    (fromHasError || toHasError) && (
      <p data-testid={'date-error' /*График работы | Ошибка при вводе даты */} className={c.error}>
        {fromHasError && toHasError ? componentTexts.DATE_FORMATS_ERROR_MSG : componentTexts.DATE_FORMAT_ERROR_MSG}
      </p>
    )

  if (disabled) {
    return <div className={cn(c.root, c.disabled)}>{renderTitle()}</div>
  }

  return (
    <div className={c.root}>
      <div className={c.timeInputBlock}>
        <DateTimePicker
          views={['hours', 'minutes']}
          disableOpenPicker
          ampm={false}
          disabled={disabledInput}
          inputRef={fromInputRef}
          value={normalizedFrom}
          desktopModeMediaQuery='@media (min-width: 1px)'
          onChange={handleFromChange}
          onError={(error) => setFromError(Boolean(error))}
          slotProps={{
            textField: {
              className: c.timeInput,
              classes: classesInput,
              variant: 'filled',
              helperText: fromHasError ? '' : undefined
            },
            field: {
              inputProps: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                'data-testid': 'schedule-time-from' /*График работы | Инпут врмени от*/
              }
            }
          }}
        />
        <DateTimePicker
          views={['hours', 'minutes']}
          disableOpenPicker
          ampm={false}
          disabled={disabledInput}
          inputRef={toInputRef}
          value={normalizedTo}
          desktopModeMediaQuery='@media (min-width: 1px)'
          {...(toHasError ? {error: true, helperText: ''} : {})}
          onChange={handleToChange}
          onError={(error) => setToError(Boolean(error))}
          slotProps={{
            textField: {
              className: c.timeInput,
              classes: classesInput,
              variant: 'filled'
            },
            field: {
              inputProps: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                'data-testid': 'schedule-time-to' /*График работы | Инпут времени до */
              }
            }
          }}
        />
      </div>
      {renderError()}
    </div>
  )
}

import {Snackbar} from '@eda-restapp/snackbars'
import {flatMapDeep} from 'lodash-es'
import {useMemo} from 'react'

import {InfiniteQueryAdapters, useApiInfiniteQuery} from '@restapp/core-api'
import type {ApiInfiniteQueryOptions} from '@restapp/core-api/hooks/useApiInfiniteQuery/types'
import type {FullPlaceType} from '@restapp/shared-api/types/places'
import type {SearchQueryRequest} from '@restapp/shared-api/types/search'

import {usePlacesMap} from './usePlacesMap'

type UseHeavyPlacesOptions = Omit<
  ApiInfiniteQueryOptions<'/4.0/restapp-front/places/v2/search', 'GET'>,
  'url' | 'method'
> &
  SearchQueryRequest & {
    enabled?: boolean
    filterPlaces?: (place: FullPlaceType) => boolean
  }

export const useHeavyPlaces = ({
  enabled = true,
  filterPlaces,
  limit = 10,
  cursor,
  sort = 'rating',
  ascending = true,
  filter = 'all',
  ...props
}: UseHeavyPlacesOptions) => {
  const heavyPlacesQuery = useApiInfiniteQuery(
    {
      url: '/4.0/restapp-front/places/v2/search',
      method: 'GET',
      params: {
        limit: limit,
        cursor: cursor,
        sort: sort,
        ascending: ascending,
        filter: filter
      },
      body: {}
    },
    {
      adapter: InfiniteQueryAdapters.PLACES_SEARCH,
      enabled,
      keepPreviousData: true,
      ...props,
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: filterPlaces
            ? data.pages.map((page) => {
                return {...page, places: page.places.filter(filterPlaces)}
              })
            : data.pages
        }
      },
      onError: (error) => {
        Snackbar.error({
          text: error.responseData?.message || error.message,
          errorCode: error.traceId
        })
      }
    }
  )

  const places = useMemo(
    () => flatMapDeep(heavyPlacesQuery.data?.pages, (page) => page.places) || [],
    [heavyPlacesQuery.data?.pages]
  )

  const getPlacesByBusinessType = (businessType: string) =>
    places.filter((place) => place.brand.business_type === businessType)

  const {getPlaceById} = usePlacesMap(places)

  return {
    places,
    placesCount: heavyPlacesQuery.data?.pages[0].meta.count_of_places || 0,
    getPlaceById,
    getPlacesByBusinessType,
    ...heavyPlacesQuery
  }
}

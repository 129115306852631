import type {Service, PlacePlus} from '@restapp/shared-api'
import {YaPlusStatusEnum} from '@restapp/shared-api'

type IEnablePlace = {
  id: number
  enableServices?: Service[]
}

type IFilterContractPlace = PlacePlus & {
  enableServices?: Service[]
}

export const filterContractPlacesToRequest = (places: PlacePlus[], selectedServices: Service[]) => {
  const placesForRequest: IEnablePlace[] = []
  const contractPlaces: IFilterContractPlace[] = []

  places.forEach((place) => {
    const placeContractInfo = place?.contractInfo

    const canBeActivatedServices = place.plus
      ?.filter((serviceInfo) => serviceInfo.status_info.status === YaPlusStatusEnum.CAN_BE_ACTIVATED)
      .map((serviceInfo) => serviceInfo.service)

    const newPlace = {id: place.id, enableServices: place.services?.enabled}

    if (!placeContractInfo) {
      placesForRequest.push({
        id: place.id,
        enableServices: place.services?.enabled.filter(
          (serviceName) => selectedServices.includes(serviceName) && canBeActivatedServices?.includes(serviceName)
        )
      })

      return
    }

    if (placeContractInfo.length < 1) {
      placesForRequest.push({
        id: place.id,
        enableServices: place.services?.enabled.filter(
          (serviceName) => selectedServices.includes(serviceName) && canBeActivatedServices?.includes(serviceName)
        )
      })
    } else {
      const serviceNotBeActivated = place.plus?.find(
        (item) => item.status_info.status !== YaPlusStatusEnum.CAN_BE_ACTIVATED
      )

      if (place.services?.enabled && place.services?.enabled.length < 2) {
        if (
          placeContractInfo.some(
            (service) => service.service === place.services?.enabled[0] && service.contract_type === 'contract'
          )
        ) {
          contractPlaces.push({...place, enableServices: newPlace.enableServices})
        } else {
          placesForRequest.push(newPlace)
        }
      } else {
        const placeContractService = placeContractInfo.find((service) => service.contract_type === 'contract')
        const isEveryServiceContract = placeContractInfo.every((service) => service.contract_type === 'contract')
        const chosenServiceWithContract = placeContractInfo.find(
          (serviceInfo) => serviceInfo.service === selectedServices[0] && serviceInfo.contract_type === 'contract'
        )

        if (selectedServices.length < 2) {
          if (chosenServiceWithContract || isEveryServiceContract) {
            if (chosenServiceWithContract?.service !== serviceNotBeActivated?.service) {
              contractPlaces.push({...place, enableServices: selectedServices})
            }
          } else {
            if (selectedServices[0] !== serviceNotBeActivated?.service) {
              placesForRequest.push({
                id: place.id,
                enableServices: place.services?.enabled.filter((service) => service === selectedServices[0])
              })
            }
          }
        } else {
          if (isEveryServiceContract) {
            if (!serviceNotBeActivated) {
              contractPlaces.push({...place, enableServices: newPlace.enableServices})
            }
          } else if (placeContractService) {
            if (serviceNotBeActivated?.service !== placeContractService.service) {
              contractPlaces.push({...place, enableServices: [placeContractService.service]})
            }

            const serviceWithoutContract = place.services?.enabled.filter(
              (service) => service !== placeContractService.service
            )

            if (serviceNotBeActivated?.service !== serviceWithoutContract?.[0]) {
              placesForRequest.push({
                id: place.id,
                enableServices: serviceWithoutContract
              })
            }
          } else {
            placesForRequest.push({
              id: place.id,
              enableServices: place.services?.enabled.filter(
                (serviceName) => selectedServices.includes(serviceName) && canBeActivatedServices?.includes(serviceName)
              )
            })
          }
        }
      }
    }
  })

  return {placesForRequest, contractPlaces}
}

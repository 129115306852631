import {PlaceTariffBadge, useIsMobileStrict} from '@eda-restapp/ui'
import cn from 'classnames'
import React from 'react'

import {useTariffBadges} from '../../hooks/useTariffBadges'
import {PlaceAlertTrialTooltip} from '../PlaceAlertTrialTooltip'

import {TariffBadgeWhiteIcon} from './TariffBadge.icon'
import useStyles from './TariffBadge.styles'

type TariffBadgeProps = {
  tariffType: string
  showTooltip?: boolean
  isShowMobile?: boolean
  untilDate?: string
  className?: string
  isTrial?: boolean
  isPaidNextTariff: boolean
}

const TariffBadge: React.FC<TariffBadgeProps> = ({
  tariffType,
  showTooltip = false,
  isShowMobile = false,
  untilDate,
  className,
  isTrial,
  isPaidNextTariff
}) => {
  const {classes: c} = useStyles()
  const isMobile = useIsMobileStrict()

  const {getTariffBadgesByType} = useTariffBadges()

  const currentBadge = getTariffBadgesByType(tariffType)

  if (!currentBadge) {
    return null
  }

  const renderContent = () => {
    if (isShowMobile || !isMobile) {
      return (
        <div className={cn(c.root, className)} style={{color: currentBadge.color, background: currentBadge.background}}>
          {currentBadge.name} <TariffBadgeWhiteIcon className={c.icon} arrowsColor={currentBadge.arrowsColor} />
        </div>
      )
    }

    return (
      <div className={c.mobileIcon}>
        <PlaceTariffBadge />
      </div>
    )
  }

  if (showTooltip) {
    return (
      <PlaceAlertTrialTooltip validUntilIso={untilDate} isPaidNextTariff={isPaidNextTariff}>
        {renderContent()}
      </PlaceAlertTrialTooltip>
    )
  }

  return (
    <div className={cn(c.root, className)} style={{color: currentBadge.color, background: currentBadge.background}}>
      {currentBadge.name}{' '}
      {isTrial && <TariffBadgeWhiteIcon className={c.icon} arrowsColor={currentBadge.arrowsColor} />}
    </div>
  )
}

export default TariffBadge

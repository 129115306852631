import {useI18n} from '@eda-restapp/i18n'
import {
  Box,
  Typography,
  PlayFillIcon,
  CheckRoundFillIcon,
  BonusIcon,
  RubFill,
  Button,
  useIsMobileStrict
} from '@eda-restapp/ui'
import cn from 'classnames'
import type {FC} from 'react'

import {useCpaCurrencyContext} from '../../../../hooks/useCpaCurrencyContext'

import styles from './BonusesGuideSection.module.css'
import {PromoCampaignCard} from './components/PromoCampaignCard/PromoCampaignCard'
import {StepCard} from './components/StepCard/StepCard'

type BonusesGuideSectionProps = {
  onRedirectToPromotion: () => void
}

export const BonusesGuideSection: FC<BonusesGuideSectionProps> = ({onRedirectToPromotion}) => {
  const {t} = useI18n()
  const isMobile = useIsMobileStrict()
  const cpaCurrencyContext = useCpaCurrencyContext()
  const currency = cpaCurrencyContext.currency

  return (
    <Box
      flexDirection='column'
      className={styles.root}
      p={isMobile ? 'm' : 'l'}
      pb={isMobile ? 'l' : 'xl'}
      alignItems='center'
    >
      <Typography
        data-testid='bonuses-guide-section-title'
        variant='title4'
        lineHeight='normal'
        thickness='medium'
        color='invert'
        mt='m'
      >
        {t('promotion.bonuses-guide-section.title', 'Как работают бонусы')}
      </Typography>
      <Typography
        data-testid='bonuses-guide-section-subtitle'
        variant='body2'
        lineHeight='loose'
        color='invert'
        className={styles.subtitleText}
        mt='l'
      >
        {currency
          ? t(
              'promotion.bonuses-guide-section.subtitle-with-sign',
              '1 бонус = 1 {sign}. За каждый заказ в рекламных кампаниях спишется 1 {sign} из выручки, остальное – бонусами. Всё отразим в финансовых отчётах.',
              {sign: currency?.sign}
            )
          : t(
              'promotion.bonuses-guide-section.subtitle',
              'Если коротко, то 1 бонус = 1 ₽. Запускайте рекламные кампании и оплачивайте бонусами продвижение. За каждый рекламный заказ спишется 1 рубль из выручки, остальное – бонусами. Всё отразим в финансовых отчётах.'
            )}
      </Typography>
      <Box flexDirection='column' mt='l' gap='m' className={styles.steps} pb='m'>
        <StepCard
          icon={
            <Box className={cn(styles.stepIconWrapper, styles.firstStepIconWrapper)}>
              <CheckRoundFillIcon className={cn(styles.stepIcon, styles.firstStepIcon)} />
            </Box>
          }
          className={styles.transparentStepCard}
          title={t('promotion.bonuses-guide-section.step-first-title', 'Начислены бонусы на продвижение')}
        />
        <StepCard
          icon={
            <Box className={cn(styles.stepIconWrapper, styles.secondStepIconWrapper)}>
              <PlayFillIcon className={cn(styles.stepIcon, styles.secondStepIcon)} />
            </Box>
          }
          className={styles.transparentStepCard}
          title={t('promotion.bonuses-guide-section.step-second-title', 'Заведите рекламную кампанию')}
          subtitle={t(
            'promotion.bonuses-guide-section.step-second-subtitle',
            'В ресторанах, на которые начислили бонусы'
          )}
          subtitleClassName={styles.halfWidth}
          actions={
            <Box className={styles.actionButton}>
              <Button.Text variant='light' size='m' onClick={onRedirectToPromotion} fullWidth>
                {t('promotion.bonuses-guide-section.step-second-button', 'Запустить продвижение')}
              </Button.Text>
            </Box>
          }
          actionsClassName={cn(styles.halfWidth, isMobile && styles.mobileActions)}
          images={<PromoCampaignCard currency={currency} />}
        />
        <StepCard
          icon={
            <Box className={cn(styles.stepIconWrapper, styles.lastStepIconWrapper)}>
              <BonusIcon className={cn(styles.stepIcon, styles.thirdStepIcon)} />
            </Box>
          }
          className={styles.blackStepCard}
          title={t(
            'promotion.bonuses-guide-section.step-third-title',
            'Будем списывать бонусы — для оплаты продвижения'
          )}
          subtitle={t('promotion.bonuses-guide-section.step-third-subtitle', 'Когда пользователь сделает заказ')}
        />
      </Box>
      <StepCard
        icon={
          <Box className={cn(styles.stepIconWrapper, styles.lastStepIconWrapper)}>
            <RubFill className={cn(styles.stepIcon, styles.fourthStepIcon)} />
          </Box>
        }
        className={cn(styles.blackStepCard, styles.lastBlackStepCard)}
        title={t('promotion.bonuses-guide-section.step-fourth-title', 'Начнем списывать деньги из выручки')}
        subtitle={t(
          'promotion.bonuses-guide-section.step-fourth-subtitle',
          'Когда бонусы или срок их действия закончатся'
        )}
      />
    </Box>
  )
}

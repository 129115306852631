import {createContext, useContext} from 'react'

import type {Currency} from '@restapp/promotion/types/common'

type CpaCurrencyContextType = {
  currency?: Currency
}

export const CpaCurrencyContext = createContext<CpaCurrencyContextType>({})

export const useCpaCurrencyContext = () => useContext(CpaCurrencyContext)

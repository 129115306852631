import {eventLogger} from '@eda-restapp/logger'
import moment from 'moment-timezone'
import React, {type FC, useCallback, useEffect, useState} from 'react'

import type {FullPlaceType, PlaceDisablingReasonCode} from '@restapp/shared-api'
import {useDisablePlace} from '@restapp/shared-places'

import {Timers} from '../../DisablePlaceModal.constants'
import type {PlaceDisableParams} from '../../DisablePlaceModal.types'

import {DisablePlaceWithOrdersModal} from './DisablePlaceWithOrdersModal'

export type DisablePlaceWithOrdersModalContainerProps = {
  canCancelOrders?: boolean
  disableReasons?: PlaceDisableParams[]
  place: FullPlaceType
  placeTimezone?: string
  onCloseClick: () => void
}

export const DisablePlaceWithOrdersModalContainer: FC<DisablePlaceWithOrdersModalContainerProps> = ({
  canCancelOrders = false,
  disableReasons,
  place,
  placeTimezone = moment.tz.guess(),
  onCloseClick
}) => {
  const [disablePeriodDuration, setDisablePeriodDuration] = useState<number | null>(null)
  const [disableReasonCode, setDisableReasonCode] = useState<PlaceDisablingReasonCode | null>(null)
  const [customDisableDuration, setCustomDisableDuration] = useState<moment.Moment | null>(null)
  const [isCancelOrdersButtonLoading, setIsCancelOrdersButtonLoading] = useState(false)
  const [isNotCancelOrdersButtonLoading, setIsNotCancelOrdersButtonLoading] = useState(false)

  const {disablePlace} = useDisablePlace(place.id, {
    onSuccess: () => {
      eventLogger({
        name: 'place_status',
        additional: {eventData: {state: 'disable', place_id: place.id, name: place.name}}
      })
    },
    onSettled: () => {
      setIsCancelOrdersButtonLoading(false)
      setIsNotCancelOrdersButtonLoading(false)
      onCloseClick()
    }
  })

  useEffect(() => {
    if (disablePeriodDuration !== Timers.OTHER && customDisableDuration) {
      setCustomDisableDuration(null)
    }
  }, [customDisableDuration, disablePeriodDuration, setCustomDisableDuration])

  const isSomeDisablePeriodSettingsExist = disableReasons?.find((disableParams) => disableParams.durations.length)
  const isNotSelectedAllDisableSettings =
    !disableReasonCode ||
    (isSomeDisablePeriodSettingsExist && !disablePeriodDuration) ||
    (disablePeriodDuration === Timers.OTHER && !customDisableDuration)

  const handlePeriodChange = useCallback((reasonCode: PlaceDisablingReasonCode) => {
    setDisableReasonCode(reasonCode)
    setDisablePeriodDuration(null)
  }, [])

  const handleDisablingPlace = (shouldCancelOrders: boolean = false) => {
    if (!disableReasonCode) {
      return
    }

    shouldCancelOrders ? setIsCancelOrdersButtonLoading(true) : setIsNotCancelOrdersButtonLoading(true)

    const placeCurrentTime = moment().tz(placeTimezone)

    disablePlace({
      availableAt:
        customDisableDuration ??
        (disablePeriodDuration === Timers.TILL_END_OF_DAY
          ? placeCurrentTime.endOf('day')
          : placeCurrentTime.add(disablePeriodDuration, 'minutes')),
      reasonCode: disableReasonCode,
      cancelOrders: shouldCancelOrders,
      placeTimezone: placeTimezone
    })
  }

  return (
    <DisablePlaceWithOrdersModal
      canCancelOrders={canCancelOrders}
      disablePeriodDuration={disablePeriodDuration}
      disableReasonCode={disableReasonCode}
      disableReasons={disableReasons}
      isCancelOrdersButtonLoading={isCancelOrdersButtonLoading}
      isFastFood={!!place.is_fast_food}
      isNotCancelOrdersButtonLoading={isNotCancelOrdersButtonLoading}
      isNotSelectedAllDisableSettings={isNotSelectedAllDisableSettings}
      placeAddress={place.address}
      placeCountryCode={place.country_code}
      placeName={place.name}
      placeTimezone={placeTimezone}
      placeType={place.type}
      handleDisablingPlace={handleDisablingPlace}
      handlePeriodChange={handlePeriodChange}
      onCloseClick={onCloseClick}
      setCustomDisableDuration={setCustomDisableDuration}
      setDisablePeriodDuration={setDisablePeriodDuration}
    />
  )
}

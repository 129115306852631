import {useExp3} from '@eda-restapp/configs'
import {Suspense, lazy} from 'react'

const MassVatDrawerLazy = lazy(() =>
  import('./components/MassVatDrawer').then(({MassVatDrawer}) => ({default: MassVatDrawer}))
)

export const AmbientMassVatModules = () => {
  const massVatConfig = useExp3('restapp_mass_vat')

  return <Suspense fallback={null}>{massVatConfig.enableDrawer && <MassVatDrawerLazy />}</Suspense>
}

import {useExp3} from '@eda-restapp/configs'
import {useMemo} from 'react'

import {useHeavyPlaces} from '@restapp/core-places'

export const useIsPromotionAvailable = () => {
  const promotionConfig = useExp3('restapp_promotion')
  const heavyPlacesQuery = useHeavyPlaces({enabled: promotionConfig.enabled, limit: 999})

  const hasPlacesWithAllowedTypes = useMemo(
    () =>
      promotionConfig.allowed_business_types.length
        ? heavyPlacesQuery.places?.some((place) =>
            promotionConfig.allowed_business_types.includes(place.brand.business_type)
          )
        : true,
    [heavyPlacesQuery.places, promotionConfig.allowed_business_types]
  )

  return promotionConfig.enabled && hasPlacesWithAllowedTypes
}

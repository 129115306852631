import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import type {DeviceInfo, NativeEvent} from '@restapp/core-native'

const initialState: NativeSliceState = {
  deviceInfo: null,
  storedEvents: []
}

export const nativeSlice = createSlice({
  name: 'native',
  initialState,
  reducers: {
    setDeviceInfo(state, action: PayloadAction<DeviceInfo>) {
      state.deviceInfo = action.payload
    },
    setStoredEvents(state, action: PayloadAction<NativeEvent[]>) {
      state.storedEvents = action.payload
    }
  },
  selectors: {
    selectDeviceInfo: (state) => state.deviceInfo,
    selectClickedEvent: (state) => {
      return state.storedEvents.find(
        (event): event is Extract<NativeEvent, {name: 'pushNotificationClicked'}> =>
          event.name === 'pushNotificationClicked'
      )
    }
  }
})

export type NativeSliceState = {
  deviceInfo: DeviceInfo | null
  /** Храним события натива полученные пока приложение было свернуто или закрыто */
  storedEvents: NativeEvent[]
}

import {useApiQuery, useApiQueryInvalidate} from '@restapp/core-api'

import type {CampaignCPA, CampaignType} from '../types/campaign'
import type {Currency} from '../types/common'

import useDirect from './useDirect'
import {useIsPromotionAvailable} from './useIsPromotionAvailable'

type UseCPACampaignsApiResultProps = {
  campaigns?: CampaignCPA[]
  isLoading: boolean
  isError: boolean
  invalidate: () => void
  dataUpdatedAt: number
  ratingThreshold?: number
  isInvalidating: boolean
  isSuccess: boolean
  isFetched: boolean
  currency?: Currency
}

type IUseCPACampaignsApiProps = {
  onInvalidationSuccess?: () => void
  enabled?: boolean
}

const useCPACampaignsApi = ({
  onInvalidationSuccess,
  enabled = true
}: IUseCPACampaignsApiProps = {}): UseCPACampaignsApiResultProps => {
  const {directToken} = useDirect()

  const isPromotionAvailable = useIsPromotionAvailable()

  const config = {
    url: '/4.0/restapp-front/marketing/v3/ad/campaigns',
    method: 'GET',
    refetchOnMount: false,
    retry: false,
    staleTime: 60_000,
    enabled: enabled && isPromotionAvailable,
    headers: directToken
      ? {
          Authorization: directToken
        }
      : undefined
  } as const

  const {data, isSuccess, isError, isFetched, dataUpdatedAt, fetchStatus} = useApiQuery(config)
  const {invalidate, isInProgress: isInvalidating} = useApiQueryInvalidate(
    [config.url, config.method as never],
    undefined,
    onInvalidationSuccess
  )

  return {
    campaigns: data?.campaigns.map((campaign) => ({
      ...campaign,
      campaign_type: campaign.campaign_type.toLowerCase() as CampaignType.cpa
    })),
    currency: data?.currency,
    ratingThreshold: isSuccess ? (data?.meta.rating_threshold ?? 0) : undefined,
    isLoading: fetchStatus == 'fetching',
    isInvalidating,
    isError,
    invalidate,
    dataUpdatedAt,
    isSuccess,
    isFetched
  }
}

export default useCPACampaignsApi

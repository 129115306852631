import moment from 'moment'

type GetBlockDateLabelProps = {
  date: string
  isFinishDate?: boolean
}

export const getBlockDateLabel = ({date, isFinishDate}: GetBlockDateLabelProps) => {
  const currentDate = moment()

  if (isFinishDate && moment(date).isBefore(currentDate)) {
    return '-'
  }

  if (moment(date).year() !== currentDate.year()) {
    return moment(date).format('DD MMM YYYY, HH:mm')
  }

  if (moment(date).month() !== currentDate.month() || moment(date).date() !== moment().date()) {
    return moment(date).format('DD MMM, HH:mm')
  }

  return moment(date).format('HH:mm')
}

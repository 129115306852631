import {useMemo} from 'react'

import type {FullPlaceType, ShortRestaurant} from '@restapp/shared-api/types/places'

export function usePlacesMap<T extends ShortRestaurant | FullPlaceType>(places?: T[]) {
  const mapIdToPlace = useMemo(
    () =>
      places?.reduce((acc, nextPlace) => {
        acc.set(nextPlace.id, nextPlace)
        return acc
      }, new Map<number, T>()),
    [places]
  )

  const getPlaceById = (placeId: number) => mapIdToPlace?.get(placeId)

  return {
    getPlaceById
  }
}

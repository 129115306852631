import {useChannelSubscription, placesChannel} from '@eda-restapp/microfrontend'
import moment from 'moment'
import React, {type FC, useMemo, useState} from 'react'

import {useHeavyPlaces} from '@restapp/core-places'
import type {FullPlaceType} from '@restapp/shared-api'
import {useBusyModeModal} from '@restapp/shared-places'

import {PlaceStatusList} from './PlaceStatusList'

export const PlaceStatusListContainer: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  useChannelSubscription(placesChannel, (msg) => {
    if (msg.type === 'open-places-control') {
      setIsModalOpen(true)
    }
  })

  const disabledHeavyPlacesQuery = useHeavyPlaces({
    enabled: true,
    filter: 'disabled',
    limit: 999
  })
  const enabledHeavyPlacesQuery = useHeavyPlaces({enabled: true, filter: 'enabled'})
  const busyModeModal = useBusyModeModal()

  const placesSortByBlockFinishDate = (a: FullPlaceType, b: FullPlaceType) => {
    const firstPlaceEndBlockDate = moment(b.blocks?.[0].finished_at)
    const secondPlaceEndBlockDate = moment(a.blocks?.[0].finished_at)

    return secondPlaceEndBlockDate.diff(firstPlaceEndBlockDate)
  }

  const sortedPlaces = useMemo(() => {
    const sortedDisabledPlaces = disabledHeavyPlacesQuery.places.sort(placesSortByBlockFinishDate)

    return [...sortedDisabledPlaces, ...enabledHeavyPlacesQuery.places]
  }, [disabledHeavyPlacesQuery.places, enabledHeavyPlacesQuery.places])

  const onRefetch = async () => {
    await enabledHeavyPlacesQuery.refetch()
  }

  return (
    <PlaceStatusList
      canLoadMorePlaces={!!enabledHeavyPlacesQuery.hasNextPage}
      isFetchingNextPlaceQueryPage={enabledHeavyPlacesQuery.isLoading || enabledHeavyPlacesQuery.isFetchingNextPage}
      isOpen={isModalOpen}
      onBusyModeButtonClick={(placeId: number) => busyModeModal.open(placeId)}
      places={sortedPlaces}
      heavyPlacesQueryLoadMore={enabledHeavyPlacesQuery.fetchNextPage}
      onRefetch={onRefetch}
      setIsOpen={setIsModalOpen}
    />
  )
}

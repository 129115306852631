import {useEffect, useRef} from 'react'

import {imageMap, type ImagePaths} from './NewYearLogo.constants'

export const useLoadGarlandImages = (
  garlandEl: HTMLElement | null,
  garlandMozEl: HTMLElement | null,
  serviceBrand: string
) => {
  const totalImagePromiseRef = useRef<Promise<string> | null>(null)
  const blackImagePromiseRef = useRef<Promise<string> | null>(null)
  const redImagePromiseRef = useRef<Promise<string> | null>(null)
  const yellowImagePromiseRef = useRef<Promise<string> | null>(null)
  const greenImagePromiseRef = useRef<Promise<string> | null>(null)

  const previousServiceBrand = useRef<string | null>(null)

  const setStyleImageVariables = (garlandEl: HTMLElement, {total, black, red, yellow, green}: ImagePaths) => {
    garlandEl.style.setProperty('--total-frame', `url(${total})`)
    garlandEl.style.setProperty('--black-frame', `url(${black})`)
    garlandEl.style.setProperty('--red-frame', `url(${red})`)
    garlandEl.style.setProperty('--yellow-frame', `url(${yellow})`)
    garlandEl.style.setProperty('--green-frame', `url(${green})`)
  }

  const restartAnimation = (restartingEl: HTMLElement) => {
    // Перезапуск анимаций
    restartingEl.style.animation = 'none'
    restartingEl.offsetHeight // Триггер рефлоу
    restartingEl.style.animation = ''
  }

  useEffect(() => {
    if (!garlandEl || !garlandMozEl) {
      return
    }

    const images = imageMap[serviceBrand]

    const fetchImageBlobUrl = async (imageCb: () => Promise<{default: string}>) => {
      const url = (await imageCb()).default
      const data = await fetch(url)
      return URL.createObjectURL(await data.blob())
    }

    if (
      !totalImagePromiseRef.current ||
      !blackImagePromiseRef.current ||
      !redImagePromiseRef.current ||
      !yellowImagePromiseRef.current ||
      !greenImagePromiseRef.current ||
      previousServiceBrand.current !== serviceBrand
    ) {
      totalImagePromiseRef.current = fetchImageBlobUrl(images.total)
      blackImagePromiseRef.current = fetchImageBlobUrl(images.black)
      redImagePromiseRef.current = fetchImageBlobUrl(images.red)
      yellowImagePromiseRef.current = fetchImageBlobUrl(images.yellow)
      greenImagePromiseRef.current = fetchImageBlobUrl(images.green)
    }

    const loadImages = async () => {
      if (
        !totalImagePromiseRef.current ||
        !blackImagePromiseRef.current ||
        !redImagePromiseRef.current ||
        !yellowImagePromiseRef.current ||
        !greenImagePromiseRef.current
      ) {
        return
      }

      try {
        const [total, black, red, yellow, green] = await Promise.all([
          totalImagePromiseRef.current,
          blackImagePromiseRef.current,
          redImagePromiseRef.current,
          yellowImagePromiseRef.current,
          greenImagePromiseRef.current
        ])

        restartAnimation(garlandEl)
        restartAnimation(garlandMozEl)

        setStyleImageVariables(garlandEl, {total, black, red, yellow, green})
        setStyleImageVariables(garlandMozEl, {total, black, red, yellow, green})

        previousServiceBrand.current = serviceBrand
      } catch {
        return
      }
    }

    void loadImages()
  }, [garlandEl, garlandMozEl, serviceBrand])
}

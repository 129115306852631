import type {QueryObserverResult} from '@tanstack/react-query'
import {useEffect} from 'react'

import type {ApiQueryConfig} from '@restapp/core-api'
import type {IApiError, ShortRestaurant} from '@restapp/shared-api'
import type {SearchQueryResponse} from '@restapp/shared-api/types/search'

import {usePlacesApi} from './usePlacesApi'
import {usePlacesMap} from './usePlacesMap'
import {usePlacesSelect} from './usePlacesSelect'

type CommonPlaces = {
  isLoading: boolean
  isFetched: boolean
  refetch?: () => Promise<QueryObserverResult<SearchQueryResponse, IApiError>>
  error?: string | null | IApiError
}

type ShortPlaces = CommonPlaces & {
  places: ShortRestaurant[]
  placesIds: number[]
  selectedPlaceId: number
  selectedPlaceIds: number[]
  getPlaceById: (placeId: number) => ShortRestaurant | undefined
  setSelectedPlaceId: (placeId: number) => void
  setSelectedPlaceIds: (placeIds: number[]) => void
}

type UsePlacesOptions = Omit<
  ApiQueryConfig<'/4.0/restapp-front/places/v1/suggest', 'GET'>,
  'select' | 'url' | 'method' | 'params' | 'refetchOnSocketEvent'
>

/**
 * По текущему апи мы обещаем всегда вернуть number, но это не так
 * поэтому фоллбечимся на этот дефолтный id, 0 потому что где то может быть проверка вида !placeId
 */
const DEFAULT_ID = 0

/**
 * Инициализация выбранных плейсов если они пустые в AutoSelectAllPlaces
 */
export function usePlaces(apiOptions: UsePlacesOptions = {}): ShortPlaces {
  const {places = [], placesIds = [], isLoading, isFetched} = usePlacesApi(apiOptions)
  const {selectedPlaceId, selectedPlaceIds, setSelectedPlaceId, setSelectedPlaceIds} = usePlacesSelect()

  // ЭТО УЖАСНЫЙ КОСТЫЛЬ. ОСТАЛСЯ ТОЛЬКО ДЛЯ ПОДДЕРЖКИ СТОРЕЙ С САЙДЕЭФФЕКТАМИ Применяем дефолтные значения, если стор пустой
  const selectedPlaceIdsWithDefault = selectedPlaceIds.length === 0 ? placesIds : selectedPlaceIds
  const selectedPlaceIdWithDefault = selectedPlaceId || selectedPlaceIdsWithDefault[0] || DEFAULT_ID

  useEffect(() => {
    if (selectedPlaceIds.length && placesIds.length) {
      // Проверяем что текущие выбранные плейсы есть в ответе ручки suggest
      // если выбранного плейса нет в ответе ручки, нужно сбросить значения
      // так исправляем ошибки при например удалении плейса через админку
      if (selectedPlaceIds.some((id) => !placesIds.includes(id))) {
        setSelectedPlaceIds(placesIds)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placesIds, selectedPlaceIds])

  return {
    places,
    isLoading,
    isFetched,
    placesIds,
    selectedPlaceId: selectedPlaceIdWithDefault,
    selectedPlaceIds: selectedPlaceIdsWithDefault,
    setSelectedPlaceId,
    setSelectedPlaceIds,
    getPlaceById: usePlacesMap(places).getPlaceById
  }
}

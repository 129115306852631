import {useIsDesktopStrict} from '@eda-restapp/ui'
import moment from 'moment'
import {useEffect, useRef} from 'react'
import useLocalStorageState from 'use-local-storage-state'

import {
  DESKTOP_SNOWFALL_CANVAS_WIDTH,
  HARD_DESTRUCT_TIMEOUT,
  SOFT_DESTRUCT_TIMEOUT,
  DAY_SHOWN_COUNT_LOCAL_STORAGE_KEY,
  LAST_SHOW_STARTED_LOCAL_STORAGE_KEY
} from './Snowfall.constants'
import {SnowfallController} from './Snowfall.controller'
import styles from './Snowfall.module.css'

type SnowfallOptions = {
  enabled?: boolean
  timeToLive: number
  maxDayCount: number
  highpEnabled: boolean
}

export const useSnowfallEffect = (anchorEl: HTMLElement | null, snowfallOptions: SnowfallOptions) => {
  const {enabled = true, timeToLive, maxDayCount, highpEnabled} = snowfallOptions

  const isDesktop = useIsDesktopStrict()

  const liveTimerRef = useRef<ReturnType<typeof setTimeout>>()

  const [lastShownTimestamp, setLastShownTimestamp] = useLocalStorageState<number>(
    LAST_SHOW_STARTED_LOCAL_STORAGE_KEY,
    {
      defaultValue: Date.now()
    }
  )

  const [shownCount, setShownCount] = useLocalStorageState<number>(DAY_SHOWN_COUNT_LOCAL_STORAGE_KEY, {
    defaultValue: 0
  })

  useEffect(() => {
    if (!anchorEl || !enabled) {
      return
    }

    if (!moment(lastShownTimestamp).startOf('day').isSame(moment(), 'day')) {
      setLastShownTimestamp(Date.now())
      setShownCount(1)
      return
    }

    if (maxDayCount && shownCount > maxDayCount) {
      return
    }

    if (timeToLive && maxDayCount) {
      clearTimeout(liveTimerRef.current)

      const dateNow = Date.now()
      let localLastShownTimestamp = lastShownTimestamp
      let localSnownCount = shownCount

      if (localLastShownTimestamp + timeToLive * 1000 < dateNow) {
        localLastShownTimestamp = dateNow
        setLastShownTimestamp(dateNow)
        setShownCount(localSnownCount + 1)
        localSnownCount += 1
        return
      }

      if (localSnownCount > maxDayCount) {
        return
      }

      const pastTime = localLastShownTimestamp - dateNow + timeToLive * 1000

      liveTimerRef.current = setTimeout(() => {
        desctructSoft()
        setTimeout(() => {
          liveTimerRef.current = undefined
        }, HARD_DESTRUCT_TIMEOUT)
      }, pastTime)
    }

    const paperEl = anchorEl.querySelector('.MuiPaper-root')

    if (!paperEl) {
      return
    }

    const canvas = document.createElement('canvas')

    canvas.id = 'gl-snowfall'
    canvas.className = styles.snowfall
    canvas.height = anchorEl.getBoundingClientRect().height
    canvas.width = isDesktop ? DESKTOP_SNOWFALL_CANVAS_WIDTH : paperEl.getBoundingClientRect().width
    canvas.style.setProperty('--opacity-transition', `${SOFT_DESTRUCT_TIMEOUT}ms`)

    const snowfallController = new SnowfallController()

    const resizeListener = (entry: ResizeObserverEntry) => {
      if (isDesktop) {
        if (canvas.height === anchorEl.getBoundingClientRect().height) {
          return
        }

        if (entry.contentBoxSize?.length > 0) {
          snowfallController.canvasResize(DESKTOP_SNOWFALL_CANVAS_WIDTH, entry.borderBoxSize[0].blockSize)
        } else {
          snowfallController.canvasResize(DESKTOP_SNOWFALL_CANVAS_WIDTH, entry.contentRect.height)
        }
      } else {
        if (entry.contentBoxSize?.length > 0) {
          snowfallController.canvasResize(paperEl.getBoundingClientRect().width, entry.borderBoxSize[0].blockSize)
        } else {
          snowfallController.canvasResize(paperEl.getBoundingClientRect().width, entry.contentRect.height)
        }
      }
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        return resizeListener(entry)
      }
    })

    resizeObserver.observe(isDesktop ? anchorEl : paperEl)

    const desctructSoft = () => {
      if (shownCount >= maxDayCount) {
        canvas.style.opacity = '0'
        setTimeout(() => {
          destructHard()
        }, SOFT_DESTRUCT_TIMEOUT)
      } else {
        setShownCount(shownCount + 1)
        setLastShownTimestamp(Date.now())
      }
    }

    const destructHard = () => {
      clearTimeout(liveTimerRef.current)
      snowfallController.destruct()
      resizeObserver.disconnect()
      unmount()
    }

    const unmount = () => {
      try {
        anchorEl.removeChild(canvas)
      } catch {
        void ''
      }
    }

    const canvasListener = (eventType: string) => {
      if (eventType === 'removeCanvas') {
        destructHard()
      }
    }

    snowfallController.subscribe(canvasListener)
    anchorEl.appendChild(canvas)

    snowfallController.initialize(canvas, !!highpEnabled)
    snowfallController.initScene()

    return () => destructHard()
  }, [
    anchorEl,
    enabled,
    highpEnabled,
    isDesktop,
    lastShownTimestamp,
    maxDayCount,
    setLastShownTimestamp,
    setShownCount,
    shownCount,
    timeToLive
  ])
}

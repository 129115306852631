import cn from 'classnames'
import {type FC} from 'react'

import styles from './NewYearLogo.module.css'

type NewYearLogoProps = {
  isSidebarVisible: boolean
  setLogoRef: (ref: HTMLElement | null) => void
  setLogoMozRef: (ref: HTMLElement | null) => void
}

export const NewYearLogo: FC<NewYearLogoProps> = ({isSidebarVisible, setLogoRef, setLogoMozRef}) => {
  return (
    <>
      <div
        ref={setLogoRef}
        className={cn(styles.imageHappy, styles.animated, isSidebarVisible && styles.visible)}
      ></div>
      <div
        ref={setLogoMozRef}
        className={cn(styles.imageHappyMoz, styles.animated, isSidebarVisible && styles.visible)}
      ></div>
    </>
  )
}

import type {MenuItem} from '@restapp/shared-api'

import strawberryThumbnails from '../assets/strawberry-thumbnails.jpg'
import strawberry from '../assets/strawberry.jpg'
import {createMockFactory} from '../mocks/createMockFactory'

export const mockMenuItem = createMockFactory<MenuItem>({
  id: '10192708',
  categoryId: '103263',
  name: 'Земляника с сахаром',
  description: '',
  price: 200.0,
  vat: 0,
  measure: 50.0,
  measureUnit: 'g',
  sortOrder: 100,
  modifierGroups: [],
  images: [{url: strawberry}],
  thumbnails: [{url: strawberryThumbnails}],
  available: true
})

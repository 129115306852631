import {useExp3} from '@eda-restapp/configs'

import {useHeavyPlaces} from '@restapp/core-places'

import {useSubscriptions} from './useSubscriptions'

export const useTariffBadge = (placeId: number) => {
  const toolsConfig = useExp3('restapp_tools')
  const {getPlaceById, places} = useHeavyPlaces({enabled: !!placeId, limit: 999})

  const placeSubscription = useSubscriptions(
    {placesIds: places.map((place) => place.id)},
    {enabled: !!placeId, select: (data) => data.subscriptions.find((item) => item.place_id === placeId)}
  )

  const isSubscriptionEnabled = toolsConfig.subscriptionPage.enabled
  const place = getPlaceById(placeId)

  const isNeedAlerting =
    isSubscriptionEnabled && place?.subscription?.is_trial && place.subscription?.need_alerting_about_finishing_trial

  if (!place?.subscription) {
    return
  }

  return {
    isSubscriptionEnabled,
    isNeedAlerting,
    validUntilIso: place?.subscription.valid_until_iso,
    tariffType: place?.subscription.tariff_type,
    isTrial: place?.subscription?.is_trial,
    nextTariff: placeSubscription.data?.next_tariff
  }
}
